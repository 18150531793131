.modal-dialog.modal-custom {
  max-width: 623px;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: bold;  
}

.modal-dialog.modal-custom .modal-content {
  border-radius: 5px;
  border:0;
}

.modal-dialog.modal-custom .modal-body {
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.modal-dialog.modal-custom .modal-content header {
  border-radius: 3px 3px 0 0;
  background-color: rgba(27, 30, 56, 0.07);
  border: 0;
  margin: 10px;
}

.modal-dialog.modal-custom .modal-content header .modal-title {
  font-weight: bold;
}

.modal-dialog .modal-footer {
  border-top: none;
  padding-left: 20px;
  padding-right: 20px;
}

#custom-modal___BV_modal_backdrop_ {
  background-color: rgba(27,30,56,0.25);
}