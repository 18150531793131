<template>
  <!-- eslint-disable -->
  <div class="">
    <tabs ref="settings-rfq-tabs" cache-lifetime="0"
          :options="{ useUrlFragment: false, defaultTabHash: computedDefaultRFQHash, isOrdered: true }"
          @changed="onRFQTabChange">
      <tab name="RFQ EMAIL" id="emailRfqPdf" ref="emailRfqPdf" class-name="tab-content-table" :order="100">
        <div class="settings-rfq-page row">
          <div class="col-md-6">
            <div class="business-account-receiving-block">
              <div class="group-title">
                Business Account Receiving Email address
              </div>
              <div class="row">
                <p class="notifications-info-text">Create a unique email name for your Business account to send RFQs
                  from your
                  Mailbox or Dealer Management System (DMS).</p>
                <div class="d-flex email-block justify-content-center">
                  <b-form-input @change="checkEmail" @focus="isFocusEmail = true;" @blur="isFocusEmail = false"
                                v-model="settings.rfq.quoteEmail" class="form-control"></b-form-input>
                  <div class="ps-email-domain">
                    {{ emailDomain }}
                  </div>
                </div>
                <div class="verify-email-text d-flex justify-content-center" style="height: 50px">
                  <template
                      v-if="!isFocusEmail && isGetResponse && settings.rfq.quoteEmailCopy !== settings.rfq.quoteEmail">
                    <p v-if="isValid && settings.rfq.quoteEmail.length" class="text-success">
                      Unique email name is available
                    </p>
                    <p v-else-if="!isValid && settings.rfq.quoteEmail.length" class="text-error">
                      This email name is already in use. Try another.
                    </p>
                  </template>
                </div>

              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab name="Quote Import" id="importRfqPdf" ref="importRfqPdf" class-name="tab-content-table" :order="200">
        <div class="settings-rfq-page row">
          <div class="col-md-6">
            <div class="group-title mt-3">
              Import Quote Options
            </div>
            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Import Quote Options</span> <br/>
                <p class="notifications-info-text">Allow PDF quotes to be manually imported in Partssearch from your
                  PC's
                  folder.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      name="check-button"
                      @change="changedBrand"
                      switch
                      v-model="settings.rfq.importQuoteOptions.importPdfQuote"
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.rfq.importQuoteOptions.importPdfQuote" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Import PDF Quote - Import Check</span> <br/>
                <p class="notifications-info-text">Use the import pre check so that you can see the lines items and
                  details to
                  before they are applied.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 125px;">
                  <label class="form-check-inline checkbox">
                    <input class="form-check-input"
                           type="checkbox"
                           @change="changedBrand"
                           :disabled="!settings.rfq.importQuoteOptions.importPdfQuote"
                           v-model="settings.rfq.importQuoteOptions.importCheck"
                    >
                    <span class="icon clickable"
                          :class="{'disabled-import-pdf-quote-checkbox': !settings.rfq.importQuoteOptions.importPdfQuote }"><i
                        class='bx bx-check'></i></span>
                    <!--                  <b-form-checkbox-->
                    <!--                      name="check-button"-->
                    <!--                      v-model="settings.rfq.importCheck"-->
                    <!--                  ></b-form-checkbox>-->
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Import CSV Quote</span> <br/>
                <p class="notifications-info-text">Allow CSV quotes to be manually imported in Partssearch from your
                  PC's
                  folder.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      name="check-button"
                      switch
                      @change="changedBrand"
                      v-model="settings.rfq.importQuoteOptions.importCsvQuote"
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.rfq.importQuoteOptions.importCsvQuote" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Import CSV Quote - Import Check</span> <br/>
                <p class="notifications-info-text">Use the import pre check so that you can see the lines items and
                  details to
                  before they are applied.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 125px;">
                  <label class="form-check-inline checkbox">
                    <input class="form-check-input"
                           type="checkbox"
                           @change="changedBrand"
                           :disabled="!settings.rfq.importQuoteOptions.importCsvQuote"
                           v-model="settings.rfq.importQuoteOptions.importCheckCsv"
                    >
                    <span class="icon clickable"
                          :class="{'disabled-import-pdf-quote-checkbox': !settings.rfq.importQuoteOptions.importCsvQuote }"><i
                        class='bx bx-check'></i></span>
                    <!--                  <b-form-checkbox-->
                    <!--                      name="check-button"-->
                    <!--                      v-model="settings.rfq.importCheck"-->
                    <!--                  ></b-form-checkbox>-->
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab name="Service LVL" id="seviceLVL" ref="seviceLVL" class-name="tab-content-table" :order="300">
        <div class="settings-rfq-page row">
          <div class="col-md-6">
            <div class="group-title">
              RFQ Response Time Management
            </div>
            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Apply Custom Response Level</span> <br/>
                <p class="notifications-info-text">Define your RFQ Response Level times to be applied for all of your
                  customers. Activate to apply over the system default.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      name="check-button"
                      @change="changedBrand"
                      switch
                      v-model="settings.rfq.customResponseLevel.active"
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.rfq.customResponseLevel.active" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 settings-rfq-table-header-left">Response Level</div>
              <div class="col-3 settings-rfq-table-header-center">Min Time</div>
              <div class="col-4 settings-rfq-table-header-right">Less or Equal to Nbr lines</div>
              <div class="col-2 settings-rfq-table-header-right">Action</div>
            </div>
            <div class="row" v-for="(itm, key) in settings.rfq.customResponseLevel.settings" :key="key">
              <div class="col-3 settings-rfq-table-block">
                <b-form-input v-model="itm.responseLevel" :disabled="!settings.rfq.customResponseLevel.active"
                              type="text"
                              @change="changedBrand"
                              class="form-control"></b-form-input>
              </div>
              <div class="col-3 settings-rfq-table-multislect">
                <multiselect
                    class="settings-rfq-response-time-multiselect"
                    v-model="itm.minTime"
                    :options="minTimeOptions"
                    :searchable="false"
                    :track-by="'key'"
                    :label="'value'"
                    :disabled="!settings.rfq.customResponseLevel.active"
                    :close-on-select="true"
                    @input="changedBrand"
                    :show-labels="false"
                    placeholder="Pick a value"
                    :allow-empty="false"
                >
                </multiselect>
              </div>
              <div class="col-4 settings-rfq-table-block">
                <limited-input :not-available-value="notAvailableNbrLines" v-model="itm.minNbrLines"
                               @update="value => onUpdateLimitedValue(itm, value)"
                               :is-disabled="!settings.rfq.customResponseLevel.active"></limited-input>
              </div>
              <div class="col-2 settings-rfq-table-block d-flex justify-content-center align-items-center">
                <i
                    :disabled="!settings.rfq.customResponseLevel.active"
                    @click="removeResponseLvl(key)"
                    :class="{
                      'clickable': settings.rfq.customResponseLevel.active,
                    }"
                    class='bx bx-trash'
                    v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Remove', placement:'leftbottom'}"
                    style="font-size: 19px; color: red; position: initial;">
                </i>
              </div>
            </div>

            <div class="mt-3 mb-4">
              <span @click="addLocation" style="cursor:pointer;" class="add-part"><i class="bx bx-plus"></i><span>Add Another Level</span></span>
            </div>


            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">RFQ Response Time Notification</span> <br/>
                <p class="notifications-info-text">Define your RFQ Response Level times to be applied for all of your
                  customers. Activate to apply over the system default.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      name="check-button"
                      switch
                      @change="changedBrand"
                      v-model="settings.rfq.responseTimeNotification.active"
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.rfq.responseTimeNotification.active" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-3 settings-rfq-table-header-left">Notification Level</div>
              <div class="col-3 settings-rfq-table-header-center">Time Left</div>
              <div class="col-3 settings-rfq-table-header-right">Colour</div>
              <div class="col-3 settings-rfq-table-header-right">Display As</div>
            </div>
            <div class="row">
              <div class="col-3 settings-rfq-table-block">Closing Soon</div>
              <div class="col-3 settings-rfq-table-multislect">
                <multiselect
                    class="settings-rfq-response-time-multiselect"
                    v-model="computedSoonNotification"
                    :options="computedNotificationTimeLevel1"
                    :disabled="!settings.rfq.responseTimeNotification.active"
                    @input="changedBrand"
                    :track-by="'key'"
                    :label="'value'"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="Pick a value"
                    :allow-empty="false"
                >
                </multiselect>
              </div>
              <div class="col-3 settings-rfq-table-block d-flex justify-content-center">
                <span>Red</span>
                <!--                  <span class="color-item" style="background-color: red"></span>-->
                <!--                    <v-input-colorpicker  v-model="color" />-->
              </div>
              <div
                  class="col-3 settings-rfq-table-block d-flex justify-content-center settings-rfq-table-multislect display-as">
                <multiselect
                    class="settings-rfq-response-time-multiselect"
                    v-model="settings.rfq.responseTimeNotification.settings.closingSoon.displayAs"
                    :disabled="!settings.rfq.responseTimeNotification.active"
                    placeholder="Pick a value"
                    @input="changedBrand"
                    :options="['Text', 'Background']"
                    :option-height="104"
                    :allow-empty="false"
                    :custom-label="customLabel"
                    :show-labels="false"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc"
                         :class="{'text-red-straboscope': props.option == 'Text', 'background-red-straboscope': props.option == 'Background'}">
                      <span class="option__title">{{ props.option }}</span>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="row">
              <div class="col-3 settings-rfq-table-block">Closing Shortly</div>
              <div class="col-3 settings-rfq-table-multislect">
                <multiselect
                    class="settings-rfq-response-time-multiselect"
                    v-model="computedShortlyNotification"
                    :options="computedNotificationTimeLevel2"
                    :disabled="!settings.rfq.responseTimeNotification.active"
                    :track-by="'key'"
                    :label="'value'"
                    :searchable="false"
                    @input="changedBrand"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="Pick a value"
                    :allow-empty="false"
                >
                </multiselect>
              </div>
              <div class="col-3 settings-rfq-table-block d-flex justify-content-center">
                <span>Orange</span>
                <!--                   <span class="color-item" style="background-color: orange"></span>-->
                <!--                    <v-input-colorpicker  v-model="color" />-->
              </div>
              <div
                  class="col-3 settings-rfq-table-block d-flex justify-content-center settings-rfq-table-multislect display-as">
                <multiselect
                    class="settings-rfq-response-time-multiselect"
                    v-model="settings.rfq.responseTimeNotification.settings.closingShortly.displayAs"
                    placeholder="Pick a value"
                    :options="['Text', 'Background']"
                    :disabled="!settings.rfq.responseTimeNotification.active"
                    :option-height="104"
                    :allow-empty="false"
                    @input="changedBrand"
                    :custom-label="customLabel"
                    :show-labels="false"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc"
                         :class="{'text-orange-straboscope': props.option == 'Text', 'background-orange-straboscope': props.option == 'Background'}">
                      <span class="option__title">{{ props.option }}</span>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="group-title" style="margin-top: 13px;">
              Quote Validity Period
            </div>
            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Apply Quote Validity Period</span> <br/>
                <p class="notifications-info-text">
                  Enable a Quote Validity Period with all Quotes that match your Business. Repairers will be forced to
                  request an update if the quote is outside of the number of days.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      v-model="settings.rfq.quoteValidityPeriod.active"
                      @change="onQuoteValidityPeriodChange"
                      name="check-button"
                      switch
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.rfq.quoteValidityPeriod.active" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" style="margin-bottom: 60px;">
              <label class="col-12 col-form-label col-lg-6" style="padding-top: 8px; padding-bottom: 0px;">
                <span class="notifications-info-title">Number of Days</span>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-input autocomplete="off" v-model="settings.rfq.quoteValidityPeriod.number"
                                :disabled="!settings.rfq.quoteValidityPeriod.active" placeholder="Days"
                                @change="onNumberOfDaysChange" @keypress="isNumber($event)"
                                class="form-control"></b-form-input>
                </div>
              </div>
            </div>

          </div>
        </div>
      </tab>
      <tab name="Stock Status" id="settingsRfqStockStatus" ref="settingsRfqStockStatus" class-name="tab-content-table"
           :order="400">
        <div class="settings-rfq-page row">
          <div class="col-md-6">
            <div class="group-title">
              Status Comment Settings
            </div>
            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Apply Status Name Text Detection</span> <br/>
                <p class="notifications-info-text">Define the Status codes and comment to auto apply with every quote
                  sent in
                  or imported.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      name="check-button"
                      switch
                      @change="changedBrand"
                      v-model="settings.rfq.statusNameTextDetection.active"
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.rfq.statusNameTextDetection.active" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 settings-rfq-table-header-left">Status Name</div>
              <div class="col-7 settings-rfq-table-header-right">Text Codes to Detect</div>
              <div class="col-2 settings-rfq-table-header-right">B.O.</div>
            </div>
            <div class="row">
              <div class="col-3 settings-rfq-table-block">In Stock</div>
              <div class="col-7 settings-rfq-table-multislect">
                <multiselect
                    class="settings-rfq-status-comment-multiselect"
                    v-model="settings.rfq.statusNameTextDetection.settings.InStock"
                    tag-placeholder="Add"
                    placeholder="Add Text"
                    :options="settings.rfq.statusNameTextDetection.settings.InStock"
                    :disabled="!settings.rfq.statusNameTextDetection.active"
                    :multiple="true"
                    :taggable="true"
                    @input="changedBrand"
                    deselectLabel="Remove"
                    selectLabel="Add"
                    @tag="val => addTag(val, settings.rfq.statusNameTextDetection.settings.InStock)">
                </multiselect>
              </div>
              <div class="col-2 settings-rfq-table-tick d-flex justify-content-center align-items-center ">
              </div>
            </div>
            <div class="row">
              <div class="col-3 settings-rfq-table-block">EX Warehouse</div>
              <div class="col-7 settings-rfq-table-multislect ">
                <multiselect
                    class="settings-rfq-status-comment-multiselect"
                    v-model="settings.rfq.statusNameTextDetection.settings.ExStock"
                    tag-placeholder="Add"
                    placeholder="Add Text"
                    :disabled="!settings.rfq.statusNameTextDetection.active"
                    :options="settings.rfq.statusNameTextDetection.settings.ExStock"
                    :multiple="true"
                    :taggable="true"
                    deselectLabel="Remove"
                    @input="changedBrand"
                    selectLabel="Add"
                    @tag="val => addTag(val, settings.rfq.statusNameTextDetection.settings.ExStock)">
                </multiselect>
              </div>
              <div class="col-2 settings-rfq-table-tick d-flex justify-content-center align-items-center ">
                <label class="form-check-inline radio" style="margin-right: 0px">
                  <input class="form-check-input"
                         type="radio"
                         value="ExStock"
                         :name="'back-order-' + companyId"
                         v-model="settings.rfq.statusNameTextDetection.backOrderCheckboxes"
                         @click="onBackOrderCheckboxesClick('ExStock')"
                         @change="changedBrand"
                  >
                  <span class="icon clickable">
                    <i class='bx bx-check'></i>
                  </span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-3 settings-rfq-table-block">Low Stock</div>
              <div class="col-7 settings-rfq-table-multislect">
                <multiselect
                    class="settings-rfq-status-comment-multiselect"
                    v-model="settings.rfq.statusNameTextDetection.settings.LowStock"
                    tag-placeholder="Add"
                    placeholder="Add Text"
                    :options="settings.rfq.statusNameTextDetection.settings.LowStock"
                    :disabled="!settings.rfq.statusNameTextDetection.active"
                    :multiple="true"
                    :taggable="true"
                    deselectLabel="Remove"
                    selectLabel="Add"
                    @input="changedBrand"
                    @tag="val => addTag(val, settings.rfq.statusNameTextDetection.settings.LowStock)">
                </multiselect>
              </div>
              <div class="col-2 settings-rfq-table-tick d-flex justify-content-center align-items-center ">
                <label class="form-check-inline radio" style="margin-right: 0px">
                  <input class="form-check-input"
                         type="radio"
                         value="LowStock"
                         @click="onBackOrderCheckboxesClick('LowStock')"
                         @change="changedBrand"
                         :name="'back-order-' + companyId"
                         v-model="settings.rfq.statusNameTextDetection.backOrderCheckboxes"
                  >
                  <span class="icon clickable">
                    <i class='bx bx-check'></i>
                  </span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-3 settings-rfq-table-block">No Stock</div>
              <div class="col-7 settings-rfq-table-multislect">
                <multiselect
                    class="settings-rfq-status-comment-multiselect"
                    v-model="settings.rfq.statusNameTextDetection.settings.NoStock"
                    tag-placeholder="Add"
                    placeholder="Add Text"
                    :disabled="!settings.rfq.statusNameTextDetection.active"
                    :options="settings.rfq.statusNameTextDetection.settings.NoStock"
                    :multiple="true"
                    :taggable="true"
                    deselectLabel="Remove"
                    selectLabel="Add"
                    @input="changedBrand"
                    @tag="val => addTag(val, settings.rfq.statusNameTextDetection.settings.NoStock)">
                </multiselect>
              </div>
              <div class="col-2 settings-rfq-table-tick d-flex justify-content-center align-items-center ">
                <label class="form-check-inline radio" style="margin-right: 0px">
                  <input class="form-check-input"
                         type="radio"
                         value="NoStock"
                         @click="onBackOrderCheckboxesClick('NoStock')"
                         @change="changedBrand"
                         :name="'back-order-' + companyId"
                         v-model="settings.rfq.statusNameTextDetection.backOrderCheckboxes"
                  >
                  <span class="icon clickable">
                    <i class='bx bx-check'></i>
                  </span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-3 settings-rfq-table-block">On Back Ord</div>
              <div class="col-7 settings-rfq-table-multislect">
                <multiselect
                    class="settings-rfq-status-comment-multiselect"
                    v-model="settings.rfq.statusNameTextDetection.settings.OnBackOrd"
                    tag-placeholder="Add"
                    placeholder="Add Text"
                    :disabled="!settings.rfq.statusNameTextDetection.active"
                    :options="settings.rfq.statusNameTextDetection.settings.OnBackOrd"
                    :multiple="true"
                    :taggable="true"
                    deselectLabel="Remove"
                    selectLabel="Add"
                    @input="changedBrand"
                    @tag="val => addTag(val, settings.rfq.statusNameTextDetection.settings.OnBackOrd)">
                </multiselect>
              </div>
              <div class="col-2 settings-rfq-table-tick d-flex justify-content-center align-items-center ">
                <label  class="form-check-inline radio" style="margin-right: 0px">
                  <input class="form-check-input"
                         type="radio"
                         @click="onBackOrderCheckboxesClick('OnBackOrd')"
                         value="OnBackOrd"
                         :name="'back-order-' + companyId"
                         @change="changedBrand"
                         v-model="settings.rfq.statusNameTextDetection.backOrderCheckboxes"
                  >
                  <span class="icon clickable">
                    <i class='bx bx-check'></i>
                  </span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-3 settings-rfq-table-block">No Longer Avail</div>
              <div class="col-7 settings-rfq-table-multislect">
                <multiselect
                    class="settings-rfq-status-comment-multiselect"
                    v-model="settings.rfq.statusNameTextDetection.settings.NoLongerAvail"
                    tag-placeholder="Add"
                    placeholder="Add Text"
                    :disabled="!settings.rfq.statusNameTextDetection.active"
                    :options="settings.rfq.statusNameTextDetection.settings.NoLongerAvail"
                    :multiple="true"
                    :taggable="true"
                    deselectLabel="Remove"
                    selectLabel="Add"
                    @input="changedBrand"
                    @tag="val => addTag(val, settings.rfq.statusNameTextDetection.settings.NoLongerAvail)">
                </multiselect>
              </div>
              <div class="col-2 settings-rfq-table-tick d-flex justify-content-center align-items-center "></div>
            </div>

            <div class="row">
              <div class="col-3 settings-rfq-table-block">NOT APPLICABLE</div>
              <div class="col-7 settings-rfq-table-multislect">
                <multiselect
                    class="settings-rfq-status-comment-multiselect"
                    v-model="settings.rfq.notApplicable"
                    tag-placeholder="Add"
                    placeholder="Add Text"
                    :disabled="!settings.rfq.statusNameTextDetection.active"
                    :options="settings.rfq.notApplicable"
                    :multiple="true"
                    :taggable="true"
                    deselectLabel="Remove"
                    selectLabel="Add"
                    @tag="val => addExtraTag(val, settings.rfq.notApplicable)">
                </multiselect>
              </div>
              <div class="col-2 settings-rfq-table-tick d-flex justify-content-center align-items-center "></div>
            </div>

            <div class="mt-3 ml-3">
              <p class="notifications-info-text">To set a line in your quote to be added as an extra line. Set the
                comment text you use from your DMS.</p>
            </div>
            <div class="row">
              <div class="col-3 settings-rfq-table-header-left">Line Type</div>
              <div class="col-9 settings-rfq-table-header-right">Text codes to Detect</div>
            </div>
            <div class="row">
              <div class="col-3 settings-rfq-table-block">Extra Part</div>
              <div class="col-9 settings-rfq-table-multislect p-0" style="padding: 10px;">
                <multiselect
                    v-model="settings.rfq.extraPartCode"
                    tag-placeholder="Add"
                    placeholder="Add Text"
                    :options="settings.rfq.extraPartCode"
                    :multiple="true"
                    :taggable="true"
                    @input="changedBrand"
                    deselectLabel="Remove"
                    selectLabel="Add"
                    @tag="val => addExtraTag(val, settings.rfq.extraPartCode)"
                    class="settings-rfq-status-comment-multiselect">
                </multiselect>
              </div>
            </div>
            <div class="row" style="margin-bottom: 70px;">
              <div class="col-3 settings-rfq-table-block">Freight</div>
              <div class="col-9 settings-rfq-table-multislect p-0" style="padding: 10px;">
                <multiselect
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                    autocomplete="off"
                    :hide-selected="true"
                    @input="changedBrand"
                    :prevent-autofocus="true"
                    :internal-search="false"
                    v-model="settings.rfq.freightCode"
                    tag-placeholder="Add"
                    placeholder="Add Text"
                    :options="settings.rfq.freightCode"
                    :multiple="true"
                    :taggable="true"
                    deselectLabel="Remove"
                    ref="extraMultiselect"
                    selectLabel="Add"
                    @tag="val => addExtraTag(val, settings.rfq.freightCode)"
                    class="settings-rfq-status-comment-multiselect">
                  <div v-if="$refs.extraMultiselect"
                       @click="addExtraTag($refs.extraMultiselect.search, settings.rfq.freightCode)"
                       class="no-result-extra" slot="noResult">{{ $refs.extraMultiselect.search }} <span
                      style="margin-top: 6px; margin-right: 2px">Add</span></div>
                </multiselect>
              </div>
            </div>
            <div class="group-title">
              Set All - Stock Status Configurator
            </div>
            <div class="row pb-3">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Set All - Stock Status</span> <br/>
                <p class="notifications-info-text">Define the Status codes and comment to auto apply with every quote
                  sent in
                  or imported.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      name="check-button"
                      switch
                      @change="changedBrand"
                      v-model="settings.rfq.stockStatusQuickComments.allEnabled"
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.rfq.stockStatusQuickComments.allEnabled" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>
              <div class="col-12 p-0 set-all-stock-status-wrapper">
                <div class="m-3">
                  <div class="d-flex mt-3 mb-3">
                    <div class="set-all-status-text">In Stock</div>
                    <i class='bx bx-plus' @click="addStockText(1)"></i>
                  </div>
                  <draggable
                      :list="settings.rfq.stockStatusQuickComments.all[1]"
                      :disabled="!settings.rfq.stockStatusQuickComments.allEnabled"
                      class="list-group"
                      @change="changedBrand"
                      ghost-class="ghost"
                  >
                    <div v-for="(item, ind) in settings.rfq.stockStatusQuickComments.all[1]">
                      <div class="d-flex align-items-center ml-5 position-relative">
                        <div class="set-all-stock-status-line"></div>
                        <b-form-input @change="changedBrand"
                                      :disabled="!settings.rfq.stockStatusQuickComments.allEnabled" maxlength="13"
                                      v-model="settings.rfq.stockStatusQuickComments.all[1][ind]" type="text"
                                      class="form-control set-all-stock-status-input ml-3">
                        </b-form-input>
                        <i class='bx bx-trash' @click="removeStockText(1, ind)"></i>
                      </div>
                    </div>
                  </draggable>
                  <div class="d-flex mt-3 mb-3">
                    <div class="set-all-status-text">EX Warehouse</div>
                    <i class='bx bx-plus' @click="addStockText(6)"></i>
                  </div>
                  <draggable
                      @change="changedBrand"
                      :list="settings.rfq.stockStatusQuickComments.all[6]"
                      :disabled="!settings.rfq.stockStatusQuickComments.allEnabled"
                      class="list-group"
                      ghost-class="ghost"
                  >
                    <div v-for="(item, ind) in settings.rfq.stockStatusQuickComments.all[6]">
                      <div class="d-flex align-items-center ml-5 position-relative">
                        <div class="set-all-stock-status-line"></div>
                        <b-form-input @change="changedBrand"
                                      :disabled="!settings.rfq.stockStatusQuickComments.allEnabled" maxlength="13"
                                      v-model="settings.rfq.stockStatusQuickComments.all[6][ind]" type="text"
                                      class="form-control set-all-stock-status-input ml-3">
                        </b-form-input>
                        <i class='bx bx-trash' @click="removeStockText(6, ind)"></i>
                      </div>
                    </div>
                  </draggable>
                  <div class="d-flex mt-3 mb-3">
                    <div class="set-all-status-text">Low Stock</div>
                    <i class='bx bx-plus' @click="addStockText(2)"></i>
                  </div>
                  <draggable
                      @change="changedBrand"
                      :list="settings.rfq.stockStatusQuickComments.all[2]"
                      :disabled="!settings.rfq.stockStatusQuickComments.allEnabled"
                      class="list-group"
                      ghost-class="ghost"
                  >
                    <div v-for="(item, ind) in settings.rfq.stockStatusQuickComments.all[2]">
                      <div class="d-flex align-items-center ml-5 position-relative">
                        <div class="set-all-stock-status-line"></div>
                        <b-form-input @change="changedBrand"
                                      :disabled="!settings.rfq.stockStatusQuickComments.allEnabled" maxlength="13"
                                      v-model="settings.rfq.stockStatusQuickComments.all[2][ind]" type="text"
                                      class="form-control set-all-stock-status-input ml-3">
                        </b-form-input>
                        <i class='bx bx-trash' @click="removeStockText(2, ind)"></i>
                      </div>
                    </div>
                  </draggable>
                  <div class="d-flex mt-3 mb-3">
                    <div class="set-all-status-text">No Stock</div>
                    <i class='bx bx-plus' @click="addStockText(3)"></i>
                  </div>
                  <draggable
                      :list="settings.rfq.stockStatusQuickComments.all[3]"
                      :disabled="!settings.rfq.stockStatusQuickComments.allEnabled"
                      class="list-group"
                      @change="changedBrand"
                      ghost-class="ghost"
                  >
                    <div v-for="(item, ind) in settings.rfq.stockStatusQuickComments.all[3]">
                      <div class="d-flex align-items-center ml-5 position-relative">
                        <div class="set-all-stock-status-line"></div>
                        <b-form-input @change="changedBrand"
                                      :disabled="!settings.rfq.stockStatusQuickComments.allEnabled" maxlength="13"
                                      v-model="settings.rfq.stockStatusQuickComments.all[3][ind]" type="text"
                                      class="form-control set-all-stock-status-input ml-3">
                        </b-form-input>
                        <i class='bx bx-trash' @click="removeStockText(3, ind)"></i>
                      </div>
                    </div>
                  </draggable>
                  <div class="d-flex mt-3 mb-3">
                    <div class="set-all-status-text">On Back Order</div>
                    <i class='bx bx-plus' @click="addStockText(4)"></i>
                  </div>
                  <draggable
                      :list="settings.rfq.stockStatusQuickComments.all[4]"
                      @change="changedBrand"
                      :disabled="!settings.rfq.stockStatusQuickComments.allEnabled"
                      class="list-group"
                      ghost-class="ghost"
                  >
                    <div v-for="(item, ind) in settings.rfq.stockStatusQuickComments.all[4]">
                      <div class="d-flex align-items-center ml-5 position-relative">
                        <div class="set-all-stock-status-line"></div>
                        <b-form-input @change="changedBrand"
                                      :disabled="!settings.rfq.stockStatusQuickComments.allEnabled" maxlength="13"
                                      v-model="settings.rfq.stockStatusQuickComments.all[4][ind]" type="text"
                                      class="form-control set-all-stock-status-input ml-3">
                        </b-form-input>
                        <i class='bx bx-trash' @click="removeStockText(4, ind)"></i>
                      </div>
                    </div>
                  </draggable>
                  <div style="opacity: 0.7" class="d-flex mt-3 mb-3">
                    <div class="set-all-status-text">No Longer Available</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="group-title">
              Stock Status Configurator
            </div>
            <div class="row">
              <label class="col-12 col-form-label">
                <p class="notifications-info-text">Manage the default comment picks from the Stock Status. Maximum 3
                  entries per stock status and 10 characters long</p>
              </label>
              <div class="col-12 p-0 stock-status-wrapper">
                <div class="m-3">
                  <div class="d-flex mt-3 mb-3">
                    <div class="set-all-status-text">In Stock</div>
                    <i class='bx bx-plus' @click="addStockLabel(1)"></i>
                  </div>
                  <div class="d-flex ml-5">
                    <draggable
                        @change="changedBrand"
                        :disabled="!settings.rfq.stockStatusQuickComments.allEnabled"
                        :list="settings.rfq.stockStatusQuickComments.single[1]"
                        class="list-group-2"
                        ghost-class="ghost"
                    >
                      <div v-for="(item, ind) in settings.rfq.stockStatusQuickComments.single[1]">
                        <div class="d-flex align-items-center position-relative">
                          <div class="stock-status-line-mobile"></div>
                          <b-form-input :disabled="!settings.rfq.stockStatusQuickComments.allEnabled" maxlength="10"
                                        v-model="settings.rfq.stockStatusQuickComments.single[1][ind]" type="text"
                                        class="form-control stock-status-input ml-2 mr-2">
                          </b-form-input>
                          <i class='bx bx-trash' @click="removeStockLabel(1, ind)"></i>
                          <div class="stock-status-line"></div>
                        </div>
                      </div>
                    </draggable>
                  </div>
                  <div class="d-flex mt-3 mb-3">
                    <div class="set-all-status-text">EX Warehouse</div>
                    <i class='bx bx-plus' @click="addStockLabel(6)"></i>
                  </div>
                  <div class="d-flex ml-5">
                    <draggable
                        @change="changedBrand"
                        :list="settings.rfq.stockStatusQuickComments.single[6]"
                        :disabled="!settings.rfq.stockStatusQuickComments.allEnabled"
                        class="list-group-2"
                        ghost-class="ghost"
                    >
                      <div v-for="(item, ind) in settings.rfq.stockStatusQuickComments.single[6]">
                        <div class="d-flex align-items-center position-relative">
                          <div class="stock-status-line-mobile"></div>
                          <b-form-input :disabled="!settings.rfq.stockStatusQuickComments.allEnabled" maxlength="10"
                                        v-model="settings.rfq.stockStatusQuickComments.single[6][ind]" type="text"
                                        class="form-control stock-status-input ml-2 mr-2">
                          </b-form-input>
                          <i class='bx bx-trash' @click="removeStockLabel(6, ind)"></i>
                          <div class="stock-status-line"></div>
                        </div>
                      </div>
                    </draggable>
                  </div>
                  <div class="d-flex mt-3 mb-3">
                    <div class="set-all-status-text">Low Stock</div>
                    <i class='bx bx-plus' @click="addStockLabel(2)"></i>
                  </div>
                  <div class="d-flex ml-5">
                    <draggable
                        :list="settings.rfq.stockStatusQuickComments.single[2]"
                        class="list-group-2"
                        @change="changedBrand"
                        :disabled="!settings.rfq.stockStatusQuickComments.allEnabled"
                        ghost-class="ghost"
                    >
                      <div v-for="(item, ind) in settings.rfq.stockStatusQuickComments.single[2]">
                        <div class="d-flex align-items-center position-relative">
                          <div class="stock-status-line-mobile"></div>
                          <b-form-input :disabled="!settings.rfq.stockStatusQuickComments.allEnabled" maxlength="10"
                                        v-model="settings.rfq.stockStatusQuickComments.single[2][ind]" type="text"
                                        class="form-control stock-status-input ml-2 mr-2">
                          </b-form-input>
                          <i class='bx bx-trash' @click="removeStockLabel(2, ind)"></i>
                          <div class="stock-status-line"></div>
                        </div>
                      </div>
                    </draggable>
                  </div>
                  <div class="d-flex mt-3 mb-3">
                    <div class="set-all-status-text">No Stock</div>
                    <i class='bx bx-plus' @click="addStockLabel(3)"></i>
                  </div>
                  <div class="d-flex ml-5">
                    <draggable
                        :list="settings.rfq.stockStatusQuickComments.single[3]"
                        class="list-group-2"
                        @change="changedBrand"
                        :disabled="!settings.rfq.stockStatusQuickComments.allEnabled"
                        ghost-class="ghost"
                    >
                      <div v-for="(item, ind) in settings.rfq.stockStatusQuickComments.single[3]">
                        <div class="d-flex align-items-center position-relative">
                          <div class="stock-status-line-mobile"></div>
                          <b-form-input :disabled="!settings.rfq.stockStatusQuickComments.allEnabled" maxlength="10"
                                        v-model="settings.rfq.stockStatusQuickComments.single[3][ind]" type="text"
                                        class="form-control stock-status-input ml-2 mr-2">
                          </b-form-input>
                          <i class='bx bx-trash' @click="removeStockLabel(3, ind)"></i>
                          <div class="stock-status-line"></div>
                        </div>
                      </div>
                    </draggable>
                  </div>
                  <div class="d-flex mt-3 mb-3">
                    <div class="set-all-status-text">On BackOrder</div>
                    <i class='bx bx-plus' @click="addStockLabel(4)"></i>
                  </div>
                  <div class="d-flex ml-5">
                    <draggable
                        @change="changedBrand"
                        :list="settings.rfq.stockStatusQuickComments.single[4]"
                        class="list-group-2"
                        ghost-class="ghost"
                        :disabled="!settings.rfq.stockStatusQuickComments.allEnabled"
                    >
                      <div v-for="(item, ind) in settings.rfq.stockStatusQuickComments.single[4]">
                        <div class="d-flex align-items-center position-relative">
                          <div class="stock-status-line-mobile"></div>
                          <b-form-input :disabled="!settings.rfq.stockStatusQuickComments.allEnabled" maxlength="10"
                                        v-model="settings.rfq.stockStatusQuickComments.single[4][ind]" type="text"
                                        class="form-control stock-status-input ml-2 mr-2">
                          </b-form-input>
                          <i class='bx bx-trash' @click="removeStockLabel(4, ind)"></i>
                          <div class="stock-status-line"></div>
                        </div>
                      </div>
                    </draggable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab name="User Assignment" id="userAssignment" ref="settingsRfqUserAssignment" class-name="tab-content-table"
           :order="500">
        <div class="settings-rfq-page row">
          <div class="col-12 col-md-6" style="padding: 0px 15px">
            <div class="group-title">
              Quote Assignment - Auto Lock
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Enable - Quote Auto lock</span> <br/>
                <p class="notifications-info-text">
                  This settings enables the ability for Auto locking of quotes to occur amongst team members.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      v-model="settings.rfq.quoteAssignmentAutoLock.quoteAutoLock"
                      @change="changedBrand"
                      name="check-button"
                      switch
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.rfq.quoteAssignmentAutoLock.quoteAutoLock" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <label class="col-12 col-form-label col-lg-6">
                  <span class="notifications-info-title">Lock Quote when Assigned</span> <br/>
                  <p class="notifications-info-text">Quotes are only be editable by the user that has been assigned the
                    quote. Those not assigned can only view the quote in ready only mode.</p>
                </label>
                <div class="d-flex justify-content-end col-12 col-lg-6 apply-to-all-customers-checkboxes"
                     style="place-items: center; padding-bottom: 20px; padding-right: 0px;">
                  <div class="text-right row justify-content-end" style="width: 78px; padding-right: 0px;">
                    <label style="cursor: pointer" class="form-check-inline radio">
                      <input class="form-check-input" type="radio"
                             name="lock-quote-assigned"
                             :value="true"
                             :disabled="!settings.rfq.quoteAssignmentAutoLock.quoteAutoLock"
                             @change="onClickLockQuoteWhenAssigned"
                             v-model="settings.rfq.quoteAssignmentAutoLock.lockQuoteWhenAssigned"
                             aria-label="Same as registered business address"
                      >
                      <span class="icon"><i class='bx bx-check'></i></span><span
                        class="text received-quote">Yes</span>
                    </label>
                  </div>
                  <div class="text-right row justify-content-end" style="width: 61px">
                    <label style="cursor: pointer" class="form-check-inline radio">
                      <input class="form-check-input" type="radio"
                             name="lock-quote-assigned"
                             :value="false"
                             @change="changedBrand"
                             :disabled="!settings.rfq.quoteAssignmentAutoLock.quoteAutoLock"
                             v-model="settings.rfq.quoteAssignmentAutoLock.lockQuoteWhenAssigned"
                             aria-label="Same as registered business address"
                      >
                      <span class="icon"><i class='bx bx-check'></i></span><span
                        class="text received-quote">No</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <label class="col-12 col-form-label col-lg-6">
                  <span class="notifications-info-title">Lock Quote when Opened</span> <br/>
                  <p class="notifications-info-text">Quotes are only be editable by the user that has the quote opened
                    in their browser. Does not require the quote to be assigned to them Others can only view the quote
                    in read only mode. Cannot be released until the current user has closed it.</p>
                </label>
                <div class="d-flex justify-content-end col-12 col-lg-6 apply-to-all-customers-checkboxes"
                     style="place-items: center; padding-bottom: 20px; padding-right: 0px;">
                  <div class="text-right row justify-content-end" style="padding-right: 0px; width: 78px">
                    <label style="cursor: pointer" class="form-check-inline radio">
                      <input class="form-check-input" type="radio"
                             name="lock-quote-opened"
                             :value="true"
                             :disabled="!settings.rfq.quoteAssignmentAutoLock.quoteAutoLock"
                             @change="onClickLockQuoteWhenOpened"
                             v-model="settings.rfq.quoteAssignmentAutoLock.lockQuoteWhenOpened"
                             aria-label="Same as registered business address"
                      >
                      <span class="icon"><i class='bx bx-check'></i></span><span
                        class="text received-quote">Yes</span>
                    </label>
                  </div>
                  <div class="text-right row justify-content-end" style="width: 61px">
                    <label style="cursor: pointer" class="form-check-inline radio">
                      <input class="form-check-input" type="radio"
                             name="lock-quote-opened"
                             :value="false"
                             @change="changedBrand"
                             :disabled="!settings.rfq.quoteAssignmentAutoLock.quoteAutoLock"
                             v-model="settings.rfq.quoteAssignmentAutoLock.lockQuoteWhenOpened"
                             aria-label="Same as registered business address"
                      >
                      <span class="icon"><i class='bx bx-check'></i></span><span
                        class="text received-quote">No</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class=""
                   style="width: 100%; height: 2px; background-color: rgba(27, 30, 56, 0.12); margin: 0px 15px 10px 15px;"></div>

              <div class="row" style="width: 100%;">
                <label class="col-12 col-form-label">
                  <span class="notifications-info-title">Display Quote Auto Lock Message</span> <br/>
                  <p class="notifications-info-text">Message displayed to Users when trying to edit a quote that has
                    been locked.</p>
                  <b-textarea v-model="settings.rfq.quoteAssignmentAutoLock.autoLockMessage" @change="changedBrand"
                              :disabled="!settings.rfq.quoteAssignmentAutoLock.quoteAutoLock"
                              style="height: 155px"></b-textarea>
                </label>
              </div>
            </div>

          </div>
        </div>
      </tab>

      <tab v-if="settings.rfq.nonInStock.isAvailableNilStock" name="Nil Stock" id="notInStock"
           ref="settingsRfqNotInStock" class-name="tab-content-table" :order="600">
        <div class="settings-rfq-page row">
          <div class="col-12 col-md-6" style="padding: 0px 15px">
            <div class="group-title">
              Form Settings
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Enable Nill Stock Form</span> <br/>
                <p class="notifications-info-text">
                  Define your business requirements from customers of requirements in order to process Credit requests
                  for review.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      name="check-button"
                      switch
                      v-model="settings.rfq.nonInStock.nonInStockFormEnable"
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.rfq.nonInStock.nonInStockFormEnable" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>


              <div class="row">
                <label class="col-12 col-form-label col-lg-6">
                  <span class="notifications-info-title">Validation QR Code</span> <br/>
                  <p class="notifications-info-text">Enable this to notify the customer that photos are required with
                    every request for processing. Requests submitted without photos will be denied.</p>
                </label>
                <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                  <div class="d-flex justify-content-between" style="width: 125px;">
                    <label class="form-check-inline checkbox">
                      <input class="form-check-input"
                             v-model="settings.rfq.nonInStock.validationQrCode"
                             :disabled="!settings.rfq.nonInStock.nonInStockFormEnable"
                             type="checkbox"
                      >
                      <span class="icon clickable"
                            :class="{'disabled-import-pdf-quote-checkbox': !settings.rfq.nonInStock.nonInStockFormEnable }"><i
                          class='bx bx-check'></i></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row" style="width: 100%;">
                <label class="col-12 col-form-label">
                  <div style="margin-bottom: 7px;">
                    <span class="notifications-info-title">Form statement - Introduction</span> <br/>
                  </div>
                  <b-textarea v-model="settings.rfq.nonInStock.formStatementIntroduction"
                              :disabled="!settings.rfq.nonInStock.nonInStockFormEnable"
                              style="height: 155px"></b-textarea>
                </label>
              </div>

              <div class="row" style="width: 100%;">
                <label class="col-12 col-form-label">
                  <div style="margin-bottom: 7px;">
                    <span class="notifications-info-title">Form statement - Body</span> <br/>
                  </div>
                  <b-textarea v-model="settings.rfq.nonInStock.formStatementBody"
                              :disabled="!settings.rfq.nonInStock.nonInStockFormEnable"
                              style="height: 155px"></b-textarea>
                </label>
              </div>

              <div class="row" style="width: 100%; margin-bottom: 40px;">
                <label class="col-12 col-form-label">
                  <span class="notifications-info-title">Terms & Conditions</span> <br/>
                  <p class="notifications-info-text">Add the credit return notice information you would like to have
                    appear for your customer everytime they raise a credit request.</p>
                  <b-textarea v-model="settings.rfq.nonInStock.tics"
                              :disabled="!settings.rfq.nonInStock.nonInStockFormEnable"
                              style="height: 155px"></b-textarea>
                </label>
              </div>


            </div>

          </div>
        </div>
      </tab>

      <tab v-if="settings?.rfq?.nilSupply" name="Nil Supply" id="nilSupply" ref="settingsRfqNilSupply"
           class-name="tab-content-table" :order="700">
        <div class="settings-rfq-page row">
          <div class="col-12 col-md-6" style="padding: 0px 15px">
            <div class="group-title">
              Nil Supply
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <p class="notifications-info-text" style="text-align: justify">
                  This Nil supply list of Part Descriptions is actively used in excluding from displaying parts for
                  which you are invited to price but do not supply for. <br/></p>
                <p class="notifications-info-text" style="text-align: justify">
                  Items matched here with the RFQ are automatically set to the stock status Nil Supply in your response
                  to the customer.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      name="check-button"
                      switch
                      v-model="settings.rfq.nilSupply.isEnableNilSupply"
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.rfq.nilSupply.isEnableNilSupply" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>

            </div>

            <div class="row" style="width: 100%; padding: 0px 15px;">
              <input v-model="nilSupplySearch" class="form-control" placeholder="Search"/>
            </div>

            <div class="row" style="width: 100%; padding: 0px 15px;">
              <div class=""
                   style="width: 100%; height: 2px; background-color: rgba(27, 30, 56, 0.12); margin-top: 15px"></div>
            </div>

            <div v-if="!isClickedOnAddPartDesc" class="row justify-content-end" style="width: 100%; padding: 0px 15px;">
              <div class="" style="margin-top: 15px">
                <button @click="addNewPartDesc" href="#" class="add-business-btn" style="margin-right: 0px;"> <i class="bx bx-plus"></i> Add Part Description</button>
              </div>
            </div>

            <div v-else class="row" style="width: 100%; padding: 0px 15px; margin-top: 15px">
              <input v-model="nilSupplyNewItm" @change="addNewNillText" ref="nillSupplyNewItm" class="form-control" placeholder="New Part Description"/>
            </div>

            <div style="margin-bottom: 50px">
              <template v-for="nilSupply in computedNilSupplyItems">
                <div class="row" style="width: 100%; padding: 0px 15px; margin-top: 15px;"
                     @contextmenu="openNilSupplyContextMenu($event, nilSupply)">
                  <div
                      style="padding-top: 10px;"
                      v-b-tooltip.hover="{
                        customClass: 'ps-tooltip nill-supply-tooltip nill-supply-tooltip-2',
                        html: true,
                        title: getTooltipInfo(nilSupply),
                        placement:'rightbottom'
                       }"
                      class="form-control">
                    {{ nilSupply.name }}</div>
                </div>
              </template>
            </div>

          </div>
        </div>
      </tab>
      <tab name="Non-Returnable" id="nonReturnable" ref="nonReturnable" class-name="tab-content-table">
        <div class="settings-rfq-page row">
          <div class="col-md-6">
            <div class="group-title mt-3">
              Part Acceptance Settings
            </div>
            <div class="row mt-3">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Non-Returnable - Acceptance Agreement</span> <br/>
                <p class="notifications-info-text">Define a Part acceptance agreement to apply for all customers to acknowledge as a digital acceptance record for In Stock parts.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <b-form-checkbox
                      name="check-button"
                      switch
                      @change="changedBrand"
                      v-model="settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                      class="switch-checkbox"
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Apply to All Customers</span> <br/>
                <p class="notifications-info-text">Selecting <b>Yes</b> applies to all customers across all RFQ's
                  (Default). Selecting <b>No</b> allows you set this by customer in the customers settings page.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <div>
                    <label class="form-check-inline radio">
                      <input
                          v-model="settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                          class="form-check-input"
                          type="radio"
                          :disabled="!settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                          @change="changedBrand"
                          :value="true">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">Yes</span>
                    </label>
                  </div>
                  <div class="">
                    <label class="form-check-inline radio">
                      <input
                           v-model="settings.rfq.partAcceptanceSettings.applyToAllCustomers"
                           class="form-check-input"
                           type="radio"
                           :disabled="!settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                           @change="changedBrand"
                          :value="false">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">No</span>
                    </label>
                  </div>

                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Display Part Acceptance - T&C's</span> <br/>
                <p class="notifications-info-text">Selecting <b>Yes</b> applies and displays your T&C's to every part
                  marked with this status to the Customer. No, excludes the display of your T&C's</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <div>
                    <label class="form-check-inline radio">
                      <input
                          v-model="settings.rfq.partAcceptanceSettings.displayPartAcceptanceTics"
                          class="form-check-input"
                          @change="changedBrand"
                          :disabled="!settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                          type="radio"
                          :value="true">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">Yes</span>
                    </label>
                  </div>
                  <div class="">
                    <label class="form-check-inline radio">
                      <input
                          v-model="settings.rfq.partAcceptanceSettings.displayPartAcceptanceTics"
                          class="form-check-input"
                          type="radio"
                          :disabled="!settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                          @change="changedBrand"
                          :value="false">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">No</span>
                    </label>
                  </div>

                </div>
              </div>
            </div>


            <div :class="{'empty-editor': isEmpty(settings.rfq.partAcceptanceSettings.tics) && settings.rfq.partAcceptanceSettings.displayPartAcceptanceTics && isClickedSave}" class="ml-3 mb-3">
              <vue-editor
                  @input="changedBrand"
                  :disabled="!settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                  v-model="settings.rfq.partAcceptanceSettings.tics"
                  :editorToolbar="editorToolbar"></vue-editor>
            </div>

            <div style="height: 5px; background-color: rgba(27, 30, 56, 0.12) !important; margin: 10px 0px"></div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Require - Acceptance of Order T&C's</span> <br/>
                <p class="notifications-info-text">Selecting <b>Yes</b> requires the customer to tick for acceptance of
                  the agreement and prevents them Ordering if not ticked.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <div>
                    <label class="form-check-inline radio">
                      <input
                          v-model="settings.rfq.partAcceptanceSettings.requireAcceptanceOfOrderTics"
                          class="form-check-input"
                          @change="changedBrand"
                          :disabled="!settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                          type="radio"
                          :value="true">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">Yes</span>
                    </label>
                  </div>
                  <div class="">
                    <label class="form-check-inline radio">
                      <input
                          v-model="settings.rfq.partAcceptanceSettings.requireAcceptanceOfOrderTics"
                          class="form-check-input"
                          :disabled="!settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                          type="radio"
                          @change="changedBrand"
                          :value="false">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">No</span>
                    </label>
                  </div>

                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Require - Signee to Add Name/Job Position</span> <br/>
                <p class="notifications-info-text">Selecting <b>Yes</b> requires the customer to type their Job
                  position/role in your customers company.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <div>
                    <label class="form-check-inline radio">
                      <input
                          v-model="settings.rfq.partAcceptanceSettings.requireSigneeToAddNameOrJobPosition"
                          class="form-check-input"
                          :disabled="!settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                          type="radio"
                          @change="changedBrand"
                          :value="true">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">Yes</span>
                    </label>
                  </div>
                  <div class="">
                    <label class="form-check-inline radio">
                      <input
                          v-model="settings.rfq.partAcceptanceSettings.requireSigneeToAddNameOrJobPosition"
                          class="form-check-input"
                          :disabled="!settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                          @change="changedBrand"
                          type="radio"
                          :value="false">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">No</span>
                    </label>
                  </div>

                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Require - E-Signature</span> <br/>
                <p class="notifications-info-text">Get your customer to E-Sign the acceptance on their Desktop, Mobile or Tablet device.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 140px;">
                  <div>
                    <label class="form-check-inline radio">
                      <input
                          v-model="settings.rfq.partAcceptanceSettings.requireESignature"
                          class="form-check-input"
                          type="radio"
                          :disabled="!settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                          @change="changedBrand"
                          :value="true">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">Yes</span>
                    </label>
                  </div>
                  <div class="">
                    <label class="form-check-inline radio">
                      <input
                          v-model="settings.rfq.partAcceptanceSettings.requireESignature"
                          class="form-check-input"
                          type="radio"
                          @change="changedBrand"
                          :disabled="!settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                          :value="false">
                      <span class="icon clickable"><i class='bx bx-check'></i></span><span
                        class="text">No</span>
                    </label>
                  </div>

                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Send Order Acceptance by</span> <br/>
                <p class="notifications-info-text">Sends the form for your customer to accept & sign by the following
                  default method.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 220px;">
                  <multiselect
                      :showLabels="false"
                      @input="changedBrand"
                      :options="acceptanceByOptions"
                      :disabled="!settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                      v-model="computedSendOrderAcceptanceBy"
                      :option-height="29"
                      :allow-empty="false"
                      :track-by="'key'"
                      :label="'value'"
                      :max-height="203"
                      :close-on-select="true"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-12 col-form-label col-lg-6">
                <span class="notifications-info-title">Notification of Acceptance</span> <br/>
                <p class="notifications-info-text">Receive notifications after the customer accepts when you're offline.</p>
              </label>
              <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
                <div class="d-flex justify-content-between" style="width: 220px;">
                  <multiselect
                      :showLabels="false"
                      @input="changedBrand"
                      :options="notificationOfAcceptanceOptions"
                      v-model="computedNotificationOfAcceptance"
                      :track-by="'key'"
                      :label="'value'"
                      :allow-empty="false"
                      :option-height="29"
                      :disabled="!settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement"
                      :max-height="203"
                      :close-on-select="true"
                  />
                </div>
              </div>
            </div>

            <p v-if="isClickedSave && isEmpty(settings.rfq.partAcceptanceSettings.tics) && settings.rfq.partAcceptanceSettings.displayPartAcceptanceTics" style="padding-left: 15px;" class="error-text">Check missing highlighted fields to save</p>

            <div class="d-flex mb-2-7 mt-15 d-flex justify-content-end align-items-center">
              <button @click="showModal" class="btn btn-secondary primary-show-example-btn mr-lg-2">Show Me an Example</button>
              <button @click="onSaveNonReturnable" class="btn btn-primary primary-save-btn">Save</button>
            </div>

          </div>
        </div>
      </tab>
    </tabs>

    <b-modal ref="non-returnable-example" id="non-returnable-modal" size="custom-non-returnable-modal" @hide="isOpenNonReturnalbleModal = false"
             title="Non-Returnable - Part Acceptance Agreement">
      <template v-slot:modal-backdrop class="my-backdrop"></template>

      <div class="modal-mark-as-returned non-returnable-supplier-modal form-container">
        <form>
          <div class="form-group row pr-3">
            <label class="col col-form-label">{{companyName}} <span class="font-weight-normal">requires you to read and accept the following Terms and Conditions for the
            supply of the following part/s with your order.</span></label>
          </div>
          <div class="form-group row pr-3">
            <label class="col col-form-label"><span class="font-weight-normal">Select the part/s in the list below to accept.</span></label>
          </div>
          <div class="d-flex justify-content-beetwen pr-3 pl-3 pt-3">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Quote Number: </p>
              <p class="">220785</p>
            </div>
          </div>
          <div class="d-flex pr-3 pl-3">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Part/s: </p>
              <p class=""></p>
            </div>
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Make: </p>
              <p class="">LEXUS</p>
            </div>
          </div>
          <div class="form-group row pr-3 pl-3">

            <div class="col-12 col-md-1" style="padding: 0px">
              <label class="input-checkbox col-form-label font-weight-bold pl-5">
                <input
                    name="part"
                    type="checkbox"
                />
                <div class="checkmark"></div>
              </label>
            </div>

            <div style="height: 32px; padding-top: 7px;" class="col-12 col-md-11 form-control">
              <b>Part: </b> Front Bumper Bar | MAXU9898911 | QTY 1 | $0,000.00
            </div>
          </div>
          <div class="form-group row pr-3 pl-3" v-if="settings.rfq.partAcceptanceSettings.displayPartAcceptanceTics">
            <label class="col col-form-label pl-0">Terms & Conditions</label>
            <div class="disabled-vue-editor-non-returnable">
              <vue-editor
                  v-model="settings.rfq.partAcceptanceSettings.tics"
                  :disabled="true"
                  :editorToolbar="editorToolbar"></vue-editor>
            </div>
            <!--            <div v-html="settings.acceptance" class="col-12 form-control non-returnable-text-message" type="text" disabled></div>-->
          </div>
          <div v-if="settings.rfq.partAcceptanceSettings.requireAcceptanceOfOrderTics" class="w-100 text-right row p-3">
            <label class="input-checkbox col-form-label font-weight-bold pl-5">
              <input
                  name="remember"
                  type="checkbox"
              />
              <div class="checkmark"></div>I have read & accept the suppliers Terms and Conditions
            </label>
          </div>

          <div v-if="settings.rfq.partAcceptanceSettings.requireESignature" class="form-group row p-3 position-relative" @mouseover="checkSignature">
            <label class="col col-form-label pl-0">E-Signature</label>
            <template>
              <div class="col-12 p-0 non-returnable-signature-block" :ref="'nonReturnableSignatureBlock'">
                <VPerfectSignature :stroke-options="strokeOptions" ref="signaturePad"
                                   class="non-returnable-signature-canvas"/>
              </div>
              <a
                  class="control-elem-1__item"
                  v-if="!isEmptySignature"
                  @click.prevent="clearSignature"
                  style="width: 40px"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Clear this signature', placement:'leftbottom'}">
                <i style="top: 9px" class="bx bx-trash"></i>
              </a>
            </template>
          </div>
          <template v-if="settings.rfq.partAcceptanceSettings.requireSigneeToAddNameOrJobPosition">
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Accepted By: </label>
              <input
                  type="text"
                  class="col-8 form-control"
              />
            </div>
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Position Held: </label>
              <input
                  type="text"
                  class="col-8 form-control"
              />
            </div>
          </template>

        </form>
      </div>
      <template v-slot:modal-footer="{ confirm, cancel }">
        <template>
          <b-button class="input-btn cancel mr-2" @click="cancel()">Close</b-button>
          <b-button class="input-btn decline">Decline</b-button>
          <b-button class="input-btn">Accept</b-button>
        </template>
      </template>
    </b-modal>

    <vue-context ref="nilSupplyMenu" class="nil-supply-context">
      <template v-slot="item">
        <ul>
          <li @click="removeNilSupply(item?.data?.item)">Remove from Nil Supply List</li>
        </ul>
      </template>
    </vue-context>

    <b-modal ref="autoLockSettingsBlock" @hide="onCloseModal" id="auto-lock-block-modal" size="custom-auto-lock-block"
             title="Auto Lock - Settings">
      <template v-slot:modal-backdrop class="my-backdrop"></template>
      <div class="modal-auto-lock-block form-container">
        <div class="pr-3 pl-3">
          <p>You cannot save without at least one of the two Auto-lock methods being chosen.</p>
        </div>
      </div>
      <template v-slot:modal-footer="{ ok }">
        <template>
          <b-button class="input-btn btn modal-auto-lock-block-btn" @click="ok()">OK</b-button>
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import Multiselect from 'vue-multiselect'
import Axios from 'axios'
import {VueEditor} from 'vue2-editor'
import limitedInput from '../../../../components/limitedInput'
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { VueContext } from 'vue-context'
import * as dayjs from "dayjs";
import { VPerfectSignature } from 'v-perfect-signature'
import _ from "lodash";
// import InputColorPicker from 'vue-native-color-picker'

export default {
  name: 'the-rfq',
  components: {
    multiselect: Multiselect,
    limitedInput,
    VueContext,
    VueEditor,
    draggable,
    VPerfectSignature
    // "v-input-colorpicker": InputColorPicker,
  },
  props: {
    propsSettings: {
      type: Object,
      default: () => {
        return {}
      }
    },
    companyName: {
      type: String,
      default: () => {
        return ''
      }
    },
    companyId: {
      type: [Number, String],
      default: () => {
        return null
      }
    },
  },
  data () {
    return {
      isValid: true,
      isOpenNonReturnalbleModal: false,
      isEmptySignature: true,
      strokeOptions: {
        size: 4,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5,
      },
      editorToolbar: [[{
        header: [false, 1, 2, 3, 4, 5, 6]
      }], ["bold", "italic", "underline", "strike"], // toggled buttons
        [{
          align: ""
        }, {
          align: "center"
        }, {
          align: "right"
        }, {
          align: "justify"
        }], [{
          list: "ordered"
        }, {
          list: "bullet"
        },], [{
          indent: "-1"
        }, {
          indent: "+1"
        }], // outdent/indent
        [{
          color: []
        }],
        ["clean"] // remove formatting button
      ],
      isGetResponse: false,
      isFocusEmail: false,
      emailDomain: appConfig.accountReceivingEmailDomain,
      minTimeOptions: [
        {
          key: '15',
          value: '15 mins'
        },
        {
          key: '30',
          value: '30 mins'
        },
        {
          key: '45',
          value: '45 mins'
        },
        {
          key: '60',
          value: '1 hour'
        },
        {
          key: '75',
          value: '1 hour 15 mins'
        },
        {
          key: '90',
          value: '1 hour 30 mins'
        },
        {
          key: '105',
          value: '1 hour 45 mins'
        },
        {
          key: '120',
          value: '2 hours'
        },
        {
          key: '135',
          value: '2 hour 15 mins'
        },
        {
          key: '150',
          value: '2 hours 30 mins'
        },
        {
          key: '165',
          value: '2 hours 45 mins'
        },
        {
          key: '180',
          value: '3 hours'
        },
      ],
      nilSupplySearch: '',
      nilSupplyNewItm: '',
      isClickedOnAddPartDesc: false,
      notificationTimeLevel1: [
        {
          key: '1',
          value: '1 min',
        },
        {
          key: '2',
          value: '2 mins',
        },
        {
          key: '3',
          value: '3 mins',
        },
        {
          key: '4',
          value: '4 mins',
        },
        {
          key: '5',
          value: '5 mins',
        },
        {
          key: '6',
          value: '6 mins',
        },
        {
          key: '7',
          value: '7 mins',
        },
        {
          key: '8',
          value: '8 mins',
        },
        {
          key: '9',
          value: '9 mins',
        },
        {
          key: '10',
          value: '10 mins',
        },
        {
          key: '11',
          value: '11 mins',
        },
        {
          key: '12',
          value: '12 mins',
        },
        {
          key: '13',
          value: '13 mins',
        },
        {
          key: '14',
          value: '14 mins',
        },
        {
          key: '15',
          value: '15 mins',
        },
        {
          key: '16',
          value: '16 mins',
        },
        {
          key: '17',
          value: '17 mins',
        },
        {
          key: '18',
          value: '18 mins',
        },
        {
          key: '19',
          value: '19 mins',
        },
        {
          key: '20',
          value: '20 mins',
        },
        {
          key: '21',
          value: '21 mins',
        },
        {
          key: '22',
          value: '22 mins',
        },
        {
          key: '23',
          value: '23 mins',
        },
        {
          key: '24',
          value: '24 mins',
        },
        {
          key: '25',
          value: '25 mins',
        },
        {
          key: '26',
          value: '26 mins',
        },
        {
          key: '27',
          value: '27 mins',
        },
        {
          key: '28',
          value: '28 mins',
        },
        {
          key: '29',
          value: '29 mins',
        },
        {
          key: '30',
          value: '30 mins',
        },
      ],
      notificationTimeLevel2: [
        {
          key: '2',
          value: '2 mins',
        },
        {
          key: '3',
          value: '3 mins',
        },
        {
          key: '4',
          value: '4 mins',
        },
        {
          key: '5',
          value: '5 mins',
        },
        {
          key: '6',
          value: '6 mins',
        },
        {
          key: '7',
          value: '7 mins',
        },
        {
          key: '8',
          value: '8 mins',
        },
        {
          key: '9',
          value: '9 mins',
        },
        {
          key: '10',
          value: '10 mins',
        },
        {
          key: '11',
          value: '11 mins',
        },
        {
          key: '12',
          value: '12 mins',
        },
        {
          key: '13',
          value: '13 mins',
        },
        {
          key: '14',
          value: '14 mins',
        },
        {
          key: '15',
          value: '15 mins',
        },
        {
          key: '16',
          value: '16 mins',
        },
        {
          key: '17',
          value: '17 mins',
        },
        {
          key: '18',
          value: '18 mins',
        },
        {
          key: '19',
          value: '19 mins',
        },
        {
          key: '20',
          value: '20 mins',
        },
        {
          key: '21',
          value: '21 mins',
        },
        {
          key: '22',
          value: '22 mins',
        },
        {
          key: '23',
          value: '23 mins',
        },
        {
          key: '24',
          value: '24 mins',
        },
        {
          key: '25',
          value: '25 mins',
        },
        {
          key: '26',
          value: '26 mins',
        },
        {
          key: '27',
          value: '27 mins',
        },
        {
          key: '28',
          value: '28 mins',
        },
        {
          key: '29',
          value: '29 mins',
        },
        {
          key: '30',
          value: '30 mins',
        },
        {
          key: '31',
          value: '31 mins',
        },
        {
          key: '32',
          value: '32 mins',
        },
        {
          key: '33',
          value: '33 mins',
        },
        {
          key: '34',
          value: '34 mins',
        },
        {
          key: '35',
          value: '35 mins',
        },
        {
          key: '36',
          value: '36 mins',
        },
        {
          key: '37',
          value: '37 mins',
        },
        {
          key: '38',
          value: '38 mins',
        },
        {
          key: '39',
          value: '39 mins',
        },
        {
          key: '40',
          value: '40 mins',
        },
        {
          key: '41',
          value: '41 mins',
        },
        {
          key: '42',
          value: '42 mins',
        },
        {
          key: '43',
          value: '43 mins',
        },
        {
          key: '44',
          value: '44 mins',
        },
        {
          key: '45',
          value: '45 mins',
        },
        {
          key: '46',
          value: '46 mins',
        },
        {
          key: '47',
          value: '47 mins',
        },
        {
          key: '48',
          value: '48 mins',
        },
        {
          key: '49',
          value: '49 mins',
        },
        {
          key: '50',
          value: '50 mins',
        },
        {
          key: '51',
          value: '51 mins',
        },
        {
          key: '52',
          value: '52 mins',
        },
        {
          key: '53',
          value: '53 mins',
        },
        {
          key: '54',
          value: '54 mins',
        },
        {
          key: '55',
          value: '55 mins',
        },
        {
          key: '56',
          value: '56 mins',
        },
        {
          key: '57',
          value: '57 mins',
        },
        {
          key: '58',
          value: '58 mins',
        },
        {
          key: '59',
          value: '59 mins',
        },
        {
          key: '60',
          value: '60 mins',
        },
      ],
      notificationOfAcceptanceOptions: [
        {
          value: 'Email',
          key: true,
        },
        {
          value: 'Disable',
          key: false,
        },
      ],
      acceptanceByOptions: [
        {
          value: 'Email',
          key: 'Email',
        },
        {
          value: 'SMS',
          key: 'SMS',
          $isDisabled: true
        },
      ],
      isClickedSave: false,
      settings: {
        rfq: {
          "partAcceptanceSettings": {
            "nonReturnableAcceptanceAgreement": false,
            "applyToAllCustomers": false,
            "displayPartAcceptanceTics": false,
            "tics": "",
            "requireAcceptanceOfOrderTics": false,
            "requireSigneeToAddNameOrJobPosition": false,
            "requireESignature": false,
            "notificationOfAcceptance": false,
            "sendOrderAcceptanceBy": ""
          },
          nilSupply: {
            isEnableNilSupply: false,
            items: [],
          },
          nonInStock: {
            nonInStockFormEnable: false,
            validationQrCode: false,
            isAvailableNilStock: false,
            formStatementIntroduction: null,
            formStatementBody: null,
            tics: null
          },
          quoteAssignmentAutoLock: {
            quoteAutoLock: false,
            lockQuoteWhenAssigned: false,
            lockQuoteWhenOpened: false,
            autoLockMessage: null,
          },
          customResponseLevel: {
            active: false,
            settings: [],
          },
          stockStatusQuickComments: {
            'allEnabled': false,
            'all': {
              '1': [],
              '2': [],
              '3': [],
              '4': [],
              '5': [],
              '6': [],
            },
            'single': {
              '1': [],
              '2': [],
              '3': [],
              '4': [],
              '5': [],
              '6': [],
            }
          },
          responseTimeNotification: {
            active: false,
            settings: {
              closingSoon: {
                colour: 'red',
                timeLeft: 10,
                notificationLevel: 'Closing Soon',
                displayAs: 'Text'
              },
              closingShortly: {
                colour: 'orange',
                timeLeft: 20,
                notificationLevel: 'Closing Shortly',
                displayAs: 'Text'
              }
            },
          },
          extraPartCode: [],
          freightCode: [],
          notApplicable: [],
          statusNameTextDetection: {
            active: false,
            backOrderCheckboxes: null,
            settings: {
              'ExStock': [],
              'NoStock': [],
              'InStock': [],
              'LowStock': [],
              'OnBackOrd': [],
              'NoLongerAvail': [],
            }
          },
          importQuoteOptions: {
            'importPdfQuote': false,
            'importCheckCsv': false,
            'importCheck': false,
            'importCsvQuote': false
          },
          quoteValidityPeriod: {
            active: false,
            number: this.quoteOpenDaysForRequest,
          },
          quoteEmail: '',
          quoteEmailCopy: '',
        }
      },
    }
  },
  mounted () {
    // this.loadSettings()
    // this.updateSettings();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    ...mapGetters({
      quoteOpenDaysForRequest: 'quoteOpenDaysForRequest',
      getterSettings: 'settings/getSettings',
      isMultipleLocations: 'settings/isMultipleLocations',
      currentCompany: 'currentCompany/getDetails',
      isUnifiedRfqSettings: 'settings/isUnifiedRfqSettings',
      getterAllUsers: 'users/getAllUsersForAssignedTo',
    }),
    computedNotificationOfAcceptance: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.notificationOfAcceptanceOptions, (itm) => {
            return itm.key === vm.settings.rfq.partAcceptanceSettings.notificationOfAcceptance
          });
          return item;
        } catch (e) {
          return {}
        }
      },
      set: function (item) {
        this.settings.rfq.partAcceptanceSettings.notificationOfAcceptance = item?.key;
      },
    },
    computedSendOrderAcceptanceBy: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.acceptanceByOptions, (itm) => {
            return itm.key === vm.settings.rfq.partAcceptanceSettings.sendOrderAcceptanceBy
          });
          return item;
        } catch (e) {
          return {}
        }
      },
      set: function (item) {
        this.settings.rfq.partAcceptanceSettings.sendOrderAcceptanceBy = item?.key;
      },
    },
    computedNilSupplyItems () {
      let items = this.settings?.rfq?.nilSupply?.items
      let self = this
      if (_.trim(self.nilSupplySearch) != '') {
        items = _.filter(items, function (itm) {
          return itm.name.includes(_.toUpper(self.nilSupplySearch))
        })
      }
      return _.uniqBy(_.orderBy(items, [item => _.trim(_.toLower(item.name))], ['asc']), 'name')
    },
    getSettings () {
      return (this.isMultipleLocations && !this.isUnifiedRfqSettings) ? _.cloneDeep(this.propsSettings) : _.cloneDeep(this.getterSettings)
    },
    computedNotificationTimeLevel1 () {
      let times = []
      times.push({ key: 1, value: '1 min' })
      for (let i = 2; i < 31; i++) {
        times.push({ key: i, value: i + ' mins' })
      }
      return times
    },
    computedNotificationTimeLevel2 () {
      let times = []
      let from = this.settings.rfq.responseTimeNotification.settings.closingSoon.timeLeft || 2
      for (let i = from; i < 61; i++) {
        times.push({ key: i, value: i + ' mins' })
      }
      return times
    },
    computedShortlyNotification: {
      get () {
        let settingsItem = this.settings.rfq.responseTimeNotification.settings.closingShortly

        try {
          let item = _.find(this.computedNotificationTimeLevel2, (itm) => {
            return Number(itm.key) === Number(settingsItem.timeLeft)
          })
          return item
        } catch (e) {
          return {}
        }
      },
      set (val) {
        let settingsItem = this.settings.rfq.responseTimeNotification.settings.closingShortly
        if (settingsItem) {
          console.log('val', val)
          settingsItem.timeLeft = val.key
        }
      }
    },
    computedSoonNotification: {
      get () {
        let settingsItem = this.settings.rfq.responseTimeNotification.settings.closingSoon

        try {
          let item = _.find(this.computedNotificationTimeLevel1, (itm) => {
            return Number(itm.key) === Number(settingsItem.timeLeft)
          })
          return item
        } catch (e) {
          return {}
        }
      },
      set (val) {
        let settingsItem = this.settings.rfq.responseTimeNotification.settings.closingSoon
        if (settingsItem) {
          settingsItem.timeLeft = val.key
        }
      }
    },
    notAvailableNbrLines () {
      let result = []
      _.forEach(this.settings.rfq.customResponseLevel.settings, (itm) => {
        result.push(String(itm.minNbrLines))
      })
      return result
    },
    filteredMinTimeOptions () {
      return _.filter(this.minTimeOptions, (itm) => {
        return !_.find(this.settings.rfq.customResponseLevel.settings, (item) => {
          return Number(itm.key) === Number(item.minTime.key)
        })
      })
    },
    computedDefaultRFQHash () {
      if (localStorage.getItem('settings.tabs.defaultRfqSettingsTab')) {
        return localStorage.getItem('settings.tabs.defaultRfqSettingsTab')
      }
      return 'emailRfqPdf'
    },
  },
  methods: {
    ...mapActions({
      removePartFromNilSupplyList: 'settings/removePartFromNilSupplyList',
      addPartFromNilSupplyList: 'settings/addPartFromNilSupplyList',
    }),
    onBackOrderCheckboxesClick(v) {
      if (this.settings.rfq.statusNameTextDetection.backOrderCheckboxes == v) {
        this.settings.rfq.statusNameTextDetection.backOrderCheckboxes = null
        this.changedBrand()
      }
    },
    handleResize() {
      if (!this.isOpenNonReturnalbleModal) {
        return
      }
      setTimeout(() => {
        let signatureCanvas = document.getElementsByClassName('non-returnable-signature-canvas')
        if (signatureCanvas && signatureCanvas[0]) {
          let width = this.$refs.nonReturnableSignatureBlock.offsetWidth;
          signatureCanvas[0].width = width
          signatureCanvas[0].style.width = width + 'px'
          signatureCanvas[0].height = 100
          signatureCanvas[0].style.height = '100px'
        }
        if (this.$refs.signaturePad) {
          this.$refs.signaturePad.inputPointsHandler()
        }
      }, 100)
    },
    onSaveNonReturnable() {
      this.isClickedSave = true;
      if (_.isEmpty(this.settings.rfq.partAcceptanceSettings.tics) && this.settings.rfq.partAcceptanceSettings.displayPartAcceptanceTics) {
        return
      }
      this.updateSettings()
    },
    checkSignature () {
      if (this?.$refs?.signaturePad) {
        this.isEmptySignature = this.$refs.signaturePad.isEmpty()
      }
    },
    clearSignature () {
      let signaturePad = this.$refs.signaturePad
      if (signaturePad) {
        signaturePad.clear()
      }
    },
    isEmpty(val) {
      return _.isEmpty(val)
    },
    showModal () {
      this.$refs['non-returnable-example'].show();
      this.isOpenNonReturnalbleModal = true
      this.$nextTick(() => {
        setTimeout(() => {
          let signatureCanvas = document.getElementsByClassName('non-returnable-signature-canvas')
          if (signatureCanvas && signatureCanvas[0]) {
            let width = this.$refs.nonReturnableSignatureBlock.offsetWidth;
            signatureCanvas[0].width = width
            signatureCanvas[0].style.width = width + 'px'
            signatureCanvas[0].height = 100
            signatureCanvas[0].style.height = '100px'
          }
        }, 100)
      })
    },
    addNewNillText() {
      if (_.isEmpty(_.trim(this.nilSupplyNewItm))) {
        return
      }
      NProgress.start()
      Axios.post('/ir/company/update-settings/' + this.companyId + '/rfq/nil-supply//add-multiple-combination', {
        partName: _.toUpper(this.nilSupplyNewItm),
      })
          .then((r) => {
            if (r?.data?._status) {
              this.$toast.success('This part has been added to the Nil Supply list')
              this.addPartFromNilSupplyList(r.data.nilSupply)
              this.isClickedOnAddPartDesc = false
              this.settings.rfq.nilSupply.items.push(r.data.nilSupply)
              this.nilSupplyNewItm = '';
            }
          })
          .finally(() => {
            NProgress.done()
          })
    },
    removeNilSupply (part) {
      NProgress.start()
      Axios.post('/ir/company/update-settings/' + this.companyId + '/rfq/nil-supply/remove', {
        partId: part.id,
      })
          .then((r) => {
            if (r?.data?._status) {
              this.$toast.success('This part has been removed from the Nil Supply list')
              this.settings.rfq.nilSupply.items = _.filter(this.settings.rfq.nilSupply.items, (itm) => {
                return Number(itm.id) !== Number(part.id)
              })
              this.removePartFromNilSupplyList(part)
            }
          })
          .finally(() => {
            NProgress.done()
          })
    },
    getTooltipInfo(v) {
      let user = _.find(this.getterAllUsers, (u) => {
        return Number(u.id) === Number(v.createdBy);
      })
      return `Added at: ${dayjs(v.createdAt).format('DD/MM/YYYY')}` + '<br/>' + `Added by: ${user?.name}`
    },
    addNewPartDesc() {
      this.isClickedOnAddPartDesc = true;
      setTimeout(() => {
        this.$refs['nillSupplyNewItm'].focus()
      }, 0)
    },
    openNilSupplyContextMenu (e, part) {
      e.preventDefault()
      this.$refs.nilSupplyMenu.open(e, { item: part })
    },
    onUpdateLimitedValue (itm, value) {
      itm.minNbrLines = value
      this.changedBrand()
    },
    onClickLockQuoteWhenOpened (v) {
      if (v) {
        this.settings.rfq.quoteAssignmentAutoLock.lockQuoteWhenAssigned = false
      }
      this.changedBrand()
    },
    onClickLockQuoteWhenAssigned (v) {
      if (v) {
        this.settings.rfq.quoteAssignmentAutoLock.lockQuoteWhenOpened = false
      }
      this.changedBrand()
    },
    changedBrand () {
      let result = _.cloneDeep(this.propsSettings)
      result.rfq = this.settings.rfq
      this.$emit('changedBrand', { value: result, isSettings: true, companyId: this.companyId })
    },
    customLabel ({ title }) {
      return `${title}`
    },
    removeResponseLvl (index) {
      if (!this.settings.rfq.customResponseLevel.active) {
        return
      }
      this.settings.rfq.customResponseLevel.settings.splice(index, 1)
      this.changedBrand()
    },
    addStockText (stockInd) {
      if (this.settings.rfq.stockStatusQuickComments.all[stockInd].length === 3 || !this.settings.rfq.stockStatusQuickComments.allEnabled) {
        return
      }
      this.settings.rfq.stockStatusQuickComments.all[stockInd].push('')
      this.changedBrand()
    },
    removeStockText (stockInd, ind) {
      if (!this.settings.rfq.stockStatusQuickComments.allEnabled) {
        return
      }
      this.settings.rfq.stockStatusQuickComments.all[stockInd].splice(ind, 1)
      this.changedBrand()
    },
    addStockLabel (stockInd) {
      if (this.settings.rfq.stockStatusQuickComments.single[stockInd].length === 3 || !this.settings.rfq.stockStatusQuickComments.allEnabled) {
        return
      }
      this.settings.rfq.stockStatusQuickComments.single[stockInd].push('')
      this.changedBrand()
    },
    removeStockLabel (stockInd, ind) {
      if (!this.settings.rfq.stockStatusQuickComments.allEnabled) {
        return
      }
      this.settings.rfq.stockStatusQuickComments.single[stockInd].splice(ind, 1)
      this.changedBrand()
    },
    onNumberOfDaysChange (v) {
      if (_.isEmpty(v)) {
        this.settings.rfq.quoteValidityPeriod.number = this.quoteOpenDaysForRequest
      }
      this.changedBrand()
    },
    onQuoteValidityPeriodChange (v) {
      if (!v) {
        this.settings.rfq.quoteValidityPeriod.number = this.quoteOpenDaysForRequest
      }
      this.changedBrand()
    },
    onCloseModal () {
      this.$refs['autoLockSettingsBlock'].hide()
    },
    onRFQTabChange (tab) {
      this.$emit('onTabChange', tab.tab.id)
      localStorage.setItem('settings.tabs.defaultRfqSettingsTab', tab.tab.id)
    },
    isNumber: function (evt) {
      if (!evt) {
        return
      }
      let charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    checkEmail () {
      this.isGetResponse = false
      Axios.post('/ir/company/settings/rfq/check-quote-email', {
        email: this.settings.rfq.quoteEmail,
      })
          .then((r) => {
            if (r?.data?._status) {
              this.isValid = true
              this.changedBrand()
            } else {
              this.isValid = false
            }
          })
          .finally(() => {
            this.isGetResponse = true
          })

    },
    addLocation () {
      if (!this.settings.rfq.customResponseLevel.active) {
        this.$toast.error('Status inactive')
        return
      } else if (this.settings.rfq.customResponseLevel.settings.length === 5) {
        this.$toast.error('Limit 5 levels')
        return
      }
      this.settings.rfq.customResponseLevel.settings.push({
        minNbrLines: '',
        minTime: {
          key: '',
          value: '',
        },
        responseLevel: '',
      })
      this.changedBrand()
    },
    updateSettings () {
      if (this.settings.rfq.quoteAssignmentAutoLock.quoteAutoLock && !this.settings.rfq.quoteAssignmentAutoLock.lockQuoteWhenAssigned && !this.settings.rfq.quoteAssignmentAutoLock.lockQuoteWhenOpened) {
        this.$refs['autoLockSettingsBlock'].show()
        return
      }
      let customResponseLevelSettings = _.map(_.cloneDeep(this.settings.rfq.customResponseLevel.settings), (itm) => {
        itm.minTime = itm.minTime.key
        return itm
      })
      console.log(this.settings.rfq.customResponseLevel.settings)
      // let customResponseLevelSettings = [];
      let newData = {
        settings: {
          'rfq': {
            "partAcceptanceSettings": {
              "nonReturnableAcceptanceAgreement": this.settings.rfq.partAcceptanceSettings.nonReturnableAcceptanceAgreement,
              "applyToAllCustomers": this.settings.rfq.partAcceptanceSettings.applyToAllCustomers,
              "displayPartAcceptanceTics": this.settings.rfq.partAcceptanceSettings.displayPartAcceptanceTics,
              "tics": this.settings.rfq.partAcceptanceSettings.tics,
              "requireAcceptanceOfOrderTics": this.settings.rfq.partAcceptanceSettings.requireAcceptanceOfOrderTics,
              "requireSigneeToAddNameOrJobPosition": this.settings.rfq.partAcceptanceSettings.requireSigneeToAddNameOrJobPosition,
              "requireESignature": this.settings.rfq.partAcceptanceSettings.requireESignature,
              "sendOrderAcceptanceBy": this.settings.rfq.partAcceptanceSettings.sendOrderAcceptanceBy,
              "notificationOfAcceptance": this.settings.rfq.partAcceptanceSettings.notificationOfAcceptance,
            },

            nilSupply: {
              isEnableNilSupply: this.settings.rfq.nilSupply.isEnableNilSupply,
            },
            nonInStock: {
              nonInStockFormEnable: this.settings.rfq.nonInStock.nonInStockFormEnable,
              validationQrCode: this.settings.rfq.nonInStock.validationQrCode,
              formStatementIntroduction: this.settings.rfq.nonInStock.formStatementIntroduction,
              isAvailableNilStock: this.settings.rfq.nonInStock.isAvailableNilStock,
              formStatementBody: this.settings.rfq.nonInStock.formStatementBody,
              tics: this.settings.rfq.nonInStock.tics
            },
            quoteAssignmentAutoLock: {
              quoteAutoLock: this.settings.rfq.quoteAssignmentAutoLock.quoteAutoLock,
              lockQuoteWhenAssigned: this.settings.rfq.quoteAssignmentAutoLock.lockQuoteWhenAssigned,
              lockQuoteWhenOpened: this.settings.rfq.quoteAssignmentAutoLock.lockQuoteWhenOpened,
              autoLockMessage: this.settings.rfq.quoteAssignmentAutoLock.autoLockMessage,
            },
            'statusNameTextDetection': {
              'active': this.settings.rfq.statusNameTextDetection.active,
              'backOrderCheckboxes': this.settings.rfq.statusNameTextDetection.backOrderCheckboxes,
              'settings': this.settings.rfq.statusNameTextDetection.settings,
            },
            'customResponseLevel': {
              'active': this.settings.rfq.customResponseLevel.active,
              'settings': customResponseLevelSettings,
            },
            'responseTimeNotification': {
              'active': this.settings.rfq.responseTimeNotification.active,
              'settings': this.settings.rfq.responseTimeNotification.settings
            },
            extraPartCode: this.settings.rfq.extraPartCode,
            notApplicable: this.settings.rfq.notApplicable,
            freightCode: this.settings.rfq.freightCode,
            quoteEmail: this.isValid ? this.settings.rfq.quoteEmail : '',
            quoteValidityPeriod: {
              active: this.settings.rfq.quoteValidityPeriod.active,
              number: this.settings.rfq.quoteValidityPeriod.number
            },
            importQuoteOptions: {
              importPdfQuote: this.settings.rfq.importQuoteOptions.importPdfQuote,
              importCheckCsv: this.settings.rfq.importQuoteOptions.importCheckCsv,
              importCheck: this.settings.rfq.importQuoteOptions.importCheck,
              importCsvQuote: this.settings.rfq.importQuoteOptions.importCsvQuote
            },
            stockStatusQuickComments: {
              'allEnabled': this.settings.rfq.stockStatusQuickComments.allEnabled,
              'all': this.settings.rfq.stockStatusQuickComments.all,
              'single': this.settings.rfq.stockStatusQuickComments.single,
            }
          }
        }
      }
      console.log(newData)
      // return
      Axios.post('/ir/company/update-settings/' + this.companyId,
          newData
      )
          .then(response => {
            if (response.data && response.data._status) {
              if (this.isValid) {
                this.settings.rfq.quoteEmailCopy = this.settings.rfq.quoteEmail
              }
              if (Number(this.companyId) === Number(this.currentCompany.id)) {
                this.$store.commit('currentCompany/updateRfqTimeNotification', {
                  closingSoon: {
                    timeLeft: this.settings.rfq.responseTimeNotification.settings.closingSoon.timeLeft,
                    displayAs: this.settings.rfq.responseTimeNotification.settings.closingSoon.displayAs
                  },
                  closingShortly: {
                    timeLeft: this.settings.rfq.responseTimeNotification.settings.closingShortly.timeLeft,
                    displayAs: this.settings.rfq.responseTimeNotification.settings.closingShortly.displayAs
                  },
                })
                this.$store.commit('currentCompany/updateRfqImportQuoteOptions', this.settings.rfq.importQuoteOptions)
                this.$store.dispatch('currentCompany/loadCompanyInfo')
                this.$store.dispatch('settings/loadSettings')
              }
              this.$toast.success('The RFQ Settings have been updated')
            } else {
              this.$toast.error('The RFQ Settings haven\'t been updated')
            }
          })
          .catch(error => {
            this.$toast.error('The RFQ Settings haven\'t been updated')
            console.log(error)
          })
    },
    loadSettings () {
      this.settings = {
        rfq: {
          customResponseLevel: {
            active: this.getSettings.rfq?.customResponseLevel?.active ? this.getSettings.rfq.customResponseLevel.active : false,
            settings: this.getSettings.rfq?.customResponseLevel?.settings ? this.getSettings.rfq.customResponseLevel.settings : [],
          },
          "partAcceptanceSettings": {
            "nonReturnableAcceptanceAgreement": this.getSettings.rfq?.partAcceptanceSettings?.nonReturnableAcceptanceAgreement ? true : false,
            "applyToAllCustomers": this.getSettings.rfq?.partAcceptanceSettings?.applyToAllCustomers ? true : false,
            "displayPartAcceptanceTics": this.getSettings.rfq?.partAcceptanceSettings?.displayPartAcceptanceTics ? true : false,
            "tics": this.getSettings.rfq?.partAcceptanceSettings?.tics ? this.getSettings.rfq.partAcceptanceSettings.tics : '',
            "requireAcceptanceOfOrderTics": this.getSettings.rfq?.partAcceptanceSettings?.requireAcceptanceOfOrderTics ? true : false,
            "requireSigneeToAddNameOrJobPosition": this.getSettings.rfq?.partAcceptanceSettings?.requireSigneeToAddNameOrJobPosition ? true : false,
            "requireESignature": this.getSettings.rfq?.partAcceptanceSettings?.requireESignature ? true : false,
            "notificationOfAcceptance": this.getSettings.rfq?.partAcceptanceSettings?.notificationOfAcceptance ? true : false,
            "sendOrderAcceptanceBy": this.getSettings.rfq?.partAcceptanceSettings?.sendOrderAcceptanceBy ? this.getSettings.rfq.partAcceptanceSettings.sendOrderAcceptanceBy : 'Email',
          },
          responseTimeNotification: {
            active: this.getSettings.rfq?.responseTimeNotification?.active ? this.getSettings.rfq.responseTimeNotification.active : false,
            settings: this.getSettings.rfq?.responseTimeNotification?.settings ? this.getSettings.rfq.responseTimeNotification.settings : {
              closingSoon: {
                colour: 'red',
                timeLeft: 10,
                notificationLevel: 'Closing Soon',
                displayAs: 'Text'
              },
              closingShortly: {
                colour: 'orange',
                timeLeft: 20,
                notificationLevel: 'Closing Shortly',
                displayAs: 'Text'
              }
            },
          },
          nilSupply: {
            isEnableNilSupply: this.getSettings?.rfq?.nilSupply?.isEnableNilSupply,
            items: this.getSettings?.rfq?.nilSupply?.items,
          },
          nonInStock: {
            nonInStockFormEnable: this.getSettings?.rfq?.nonInStock?.nonInStockFormEnable ? true : false,
            validationQrCode: this.getSettings?.rfq?.nonInStock?.validationQrCode ? true : false,
            isAvailableNilStock: this.getSettings?.rfq?.nonInStock?.isAvailableNilStock ? true : false,
            formStatementIntroduction: this.getSettings?.rfq?.nonInStock?.formStatementIntroduction ? this.getSettings.rfq.nonInStock.formStatementIntroduction : null,
            formStatementBody: this.getSettings?.rfq?.nonInStock?.formStatementBody ? this.getSettings.rfq.nonInStock.formStatementBody : null,
            tics: this.getSettings?.rfq?.nonInStock?.tics ? this.getSettings.rfq.nonInStock.tics : null
          },
          quoteAssignmentAutoLock: {
            quoteAutoLock: this.getSettings?.rfq?.quoteAssignmentAutoLock?.quoteAutoLock ? true : false,
            lockQuoteWhenAssigned: this.getSettings?.rfq?.quoteAssignmentAutoLock?.lockQuoteWhenAssigned ? true : false,
            lockQuoteWhenOpened: this.getSettings?.rfq?.quoteAssignmentAutoLock?.lockQuoteWhenOpened ? true : false,
            autoLockMessage: this.getSettings?.rfq?.quoteAssignmentAutoLock?.autoLockMessage ? this.getSettings.rfq.quoteAssignmentAutoLock.autoLockMessage : '',
          },
          stockStatusQuickComments: {
            'allEnabled': this.getSettings?.rfq?.stockStatusQuickComments?.allEnabled ? this.getSettings.rfq.stockStatusQuickComments.allEnabled : false,
            'all': this.getSettings?.rfq?.stockStatusQuickComments?.all ? this.getSettings.rfq.stockStatusQuickComments.all : {
              '1': [],
              '2': [],
              '3': [],
              '4': [],
              '5': [],
              '6': []
            },
            'single': this.getSettings?.rfq?.stockStatusQuickComments?.single ? this.getSettings.rfq.stockStatusQuickComments.single : {
              '1': [],
              '2': [],
              '3': [],
              '4': [],
              '5': [],
              '6': []
            },
          },
          statusNameTextDetection: {
            active: this.getSettings.rfq?.statusNameTextDetection?.active ? this.getSettings.rfq.statusNameTextDetection.active : false,
            backOrderCheckboxes: this.getSettings.rfq?.statusNameTextDetection?.backOrderCheckboxes ? this.getSettings.rfq.statusNameTextDetection.backOrderCheckboxes : null,
            settings: this.getSettings.rfq?.statusNameTextDetection?.settings ? this.getSettings.rfq.statusNameTextDetection.settings : {
              'ExStock': [],
              'NoStock': [],
              'InStock': [],
              'LowStock': [],
              'OnBackOrd': [],
              'NoLongerAvail': [],
            }
          },
          notApplicable: this.getSettings.rfq?.notApplicable ? this.getSettings.rfq.notApplicable : [],
          extraPartCode: this.getSettings.rfq?.extraPartCode ? this.getSettings.rfq.extraPartCode : [],
          freightCode: this.getSettings.rfq?.freightCode ? this.getSettings.rfq.freightCode : [],
          quoteEmail: this.getSettings.rfq?.quoteEmail ? this.getSettings.rfq.quoteEmail : '',
          quoteEmailCopy: this.getSettings.rfq?.quoteEmail ? this.getSettings.rfq.quoteEmail : '',
          importQuoteOptions: {
            importPdfQuote: this.getSettings.rfq?.importQuoteOptions?.importPdfQuote ? this.getSettings.rfq.importQuoteOptions.importPdfQuote : false,
            importCheckCsv: this.getSettings.rfq?.importQuoteOptions?.importCheckCsv ? this.getSettings.rfq.importQuoteOptions.importCheckCsv : false,
            importCheck: this.getSettings.rfq?.importQuoteOptions?.importCheck ? this.getSettings.rfq.importQuoteOptions.importCheck : false,
            importCsvQuote: this.getSettings.rfq?.importQuoteOptions?.importCsvQuote ? this.getSettings.rfq.importQuoteOptions.importCsvQuote : false,
          },
          quoteValidityPeriod: {
            active: this.getSettings.rfq?.quoteValidityPeriod?.active ? this.getSettings.rfq.quoteValidityPeriod.active : false,
            number: Number(this.getSettings.rfq?.quoteValidityPeriod?.number) ? this.getSettings.rfq.quoteValidityPeriod.number : this.quoteOpenDaysForRequest,
          }
        }
      }

      /*eslint-disable */
      if (this.settings.rfq.statusNameTextDetection.settings.hasOwnProperty('inStock')) {
        let options = _.cloneDeep(this.settings.rfq.statusNameTextDetection.settings.inStock)
        delete this.settings.rfq.statusNameTextDetection.settings.inStock
        this.$set(this.settings.rfq.statusNameTextDetection.settings, 'InStock', options)
      }

      // if (!this.settings?.rfq?.responseTimeNotification?.settings?.[0]?.hasOwnProperty('displayAs')) {
      //   this.$set(this.settings.rfq.responseTimeNotification.settings[0], 'displayAs', 'Text')
      //   this.$set(this.settings.rfq.responseTimeNotification.settings[1], 'displayAs', 'Text')
      // }

      _.forEach(this.settings.rfq.customResponseLevel.settings, (itm) => {
        let value = itm.minTime
        if (!value.key) {
          let opt = _.find(this.minTimeOptions, (item) => {
            return Number(item.key) === Number(value)
          })
          if (opt) {
            itm.minTime = {
              key: opt.key,
              value: opt.value,
            }
          } else {
            itm.minTime = {
              key: '',
              value: '',
            }
          }
        }
      })

    },
    isUseTag (val) {
      let value1 = _.find(this.settings.rfq.statusNameTextDetection.settings.InStock, (itm) => {
        return itm === val
      })
      let value2 = _.find(this.settings.rfq.statusNameTextDetection.settings.ExStock, (itm) => {
        return itm === val
      })
      let value3 = _.find(this.settings.rfq.statusNameTextDetection.settings.LowStock, (itm) => {
        return itm === val
      })
      let value4 = _.find(this.settings.rfq.statusNameTextDetection.settings.NoLongerAvail, (itm) => {
        return itm === val
      })
      let value5 = _.find(this.settings.rfq.statusNameTextDetection.settings.NoStock, (itm) => {
        return itm === val
      })
      let value6 = _.find(this.settings.rfq.statusNameTextDetection.settings.OnBackOrd, (itm) => {
        return itm === val
      })
      let value7 = _.find(this.settings.rfq.notApplicable, (itm) => {
        return itm === val
      })
      let value8 = _.find(this.settings.rfq.freightCode, (itm) => {
        return itm === val
      })
      let value9 = _.find(this.settings.rfq.extraPartCode, (itm) => {
        return itm === val
      })

      return !!(_.isEmpty(value1) && _.isEmpty(value2) && _.isEmpty(value3) && _.isEmpty(value4) && _.isEmpty(value5) && _.isEmpty(value6) && _.isEmpty(value7) && _.isEmpty(value8) && _.isEmpty(value9))
    },
    addExtraTag (newTag, option) {
      if (option.includes(newTag)) {
        this.$toast.error('This name is already in use')
        return
      }
      option.push(newTag)
      this.changedBrand()
    },
    addTag (newTag, option) {
      if (!this.isUseTag(newTag)) {
        this.$toast.error('This name is already in use')
        return
      }

      option.push(newTag)
      this.changedBrand()
    }
  },
  watch: {
    getterSettings: {
      handler () {
        if (!this.isMultipleLocations) {
          this.loadSettings()
        }
      },
      immediate: true,
    },
    propsSettings: {
      handler () {
        if (this.isMultipleLocations) {
          this.loadSettings()
        }
      },
      immediate: true,
    },
  }
}
</script>

<style>
.nill-supply-tooltip .tooltip-inner {
  border-radius: 0px 10px 10px 10px !important;
}
.nill-supply-tooltip-2 .tooltip-inner {
  text-align: left !important;
}
.site-content .business-profile-tabs .nil-supply-context {
  width: 229px;
}

.nil-supply-context ul {
  padding: 0px !important;
}

.nil-supply-context li {
  padding-left: 15px !important;
}

.site-content .business-profile-tabs .nil-supply-context ul li:hover {
  color: #79B1BB !important;
  background-color: rgba(41, 187, 193, 0.1) !important;
  font-weight: 600;
}

.settings-rfq-page .business-account-receiving-block {
  margin-bottom: 0px;
}

.settings-rfq-page .no-result-extra {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  color: #6c757d
}

.settings-rfq-page li:has(.no-result-extra) {
  background-color: #f5f5f5 !important
}

.settings-rfq-page .business-account-receiving-block .group-title {
  margin-top: 15px;
}

.settings-rfq-page .business-account-receiving-block .row {
  padding: 10px 15px;
}

.settings-rfq-page .business-account-receiving-block .form-control {
  max-width: 222px;
  margin-right: 23px;
  text-align: end;
}

.settings-rfq-page .business-account-receiving-block .email-block {
  width: 100%;
  place-items: center;
  margin-top: 10px;
}

.settings-rfq-page .business-account-receiving-block .ps-email-domain {
  font-weight: 700;
}

.settings-rfq-page .business-account-receiving-block .text-success {
  color: #00b050;
  font-weight: 700;
  margin-top: 15px;
  width: 388px;
}

.settings-rfq-page .business-account-receiving-block .text-error {
  color: #ff0000;
  font-weight: 700;
  width: 388px;
  margin-top: 15px;
}

.settings-rfq-page .business-account-receiving-block .verify-email-text {
  width: 100%;
}

.settings-rfq-page .color-item {
  width: 40px;
  height: 25px;
  border-radius: 15px;
}

.settings-rfq-page .settings-rfq-status-comment-multiselect .multiselect__tags {
  border: none;
  padding: 10px 35px 10px 0;
  height: auto;
}

.settings-rfq-page .settings-rfq-status-comment-multiselect .multiselect__tags-wrap,
.settings-rfq-page .settings-rfq-status-comment-multiselect .multiselect__placeholder {
  padding-left: 15px;
}

.settings-rfq-page .settings-rfq-status-comment-multiselect .multiselect__tags-wrap {
  height: auto;
  display: block !important;
}

.settings-rfq-page .settings-rfq-status-comment-multiselect .multiselect__placeholder {
  display: none;
}

.settings-rfq-page .settings-rfq-status-comment-multiselect .multiselect__content-wrapper {
  top: 60px;
}

.settings-rfq-page .settings-rfq-status-comment-multiselect .multiselect__input {
  padding-left: 15px;
  background-color: white;
  height: 100%;
}

.settings-rfq-page .settings-rfq-response-time-multiselect .multiselect__tags {
  border: 0;
}

.settings-rfq-page .display-as .multiselect__option {
  padding: 0;
}

.settings-rfq-page .display-as .settings-rfq-response-time-multiselect .multiselect__tags .multiselect__single {
  font-weight: bold;
  color: #1C1F39;
}

.settings-rfq-page .display-as .multiselect__option--selected.multiselect__option--highlight {
  background-color: #fff !important;
}

.modal-custom-auto-lock-block .modal-header {
  color: #fff;
  background-color: #29BBC1;
  padding: 13px 28px;
}

.modal-custom-auto-lock-block {
  max-width: 500px !important;
  margin: 15rem auto !important;
}

.modal-custom-auto-lock-block .modal-content {
  border: none;
  border-radius: 5px;
}

.modal-auto-lock-block-btn {
  width: 60px !important;
  height: 38px !important;
}
</style>

<style scoped>
.list-group-2 {
  flex-direction: row !important;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.group-title {
  height: 48px;
  background-color: rgba(27, 30, 56, 0.12) !important;
  color: #1C1F39 !important;
  font-size: 13px !important;
  font-weight: bold;
  line-height: 18px;
  padding: 15px;
  margin-bottom: 1px;
  border-radius: 3px;
}

.settings-rfq-table-header-left,
.settings-rfq-table-header-right,
.settings-rfq-table-header-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  background-color: #F2F2F2 !important;
  color: #1C1F39 !important;
  font-size: 13px !important;
  font-weight: bold;
  line-height: 18px;
  padding: 0px 0px 0px 15px;
  margin-bottom: 1px;
  border: 1px solid rgba(27, 30, 56, 0.25);
}

.settings-rfq-table-header-right,
.settings-rfq-table-header-center {
  border-left: none;
}

.settings-rfq-table-block {
  font-size: 12px;
  font-weight: bold;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-top: none;
  padding: 15px;
}
.settings-rfq-table-tick{
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-left: none;
  border-top: none;
  padding-left: 25px;
}

.settings-rfq-table-right {
  font-size: 12px;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-top: none;
  border-left: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.settings-rfq-table-multislect {
  font-size: 12px;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-top: none;
  border-left: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.add-part {
  color: #29BBC1;
  opacity: 1;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin: 30px 15px;
}

.add-part i {
  font-size: 16px;
}

.add-part span {
  display: inline-block;
  position: relative;
  bottom: 2px;
  font-size: 13px !important;
}

.icp__input {
  border-radius: 3px !important;
}

.disabled-import-pdf-quote-checkbox {
  color: lightgray !important;

}

.theme-supplier label.checkbox > input[type="checkbox"]:disabled + span.icon {
  border-color: lightgray !important;
  cursor: not-allowed;
}

label.checkbox > input[type="checkbox"]:checked:disabled + span.icon i {
  color: lightgray !important;
  cursor: not-allowed;
}

.text-red-straboscope,
.text-orange-straboscope {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  animation-name: text-straboscope;
  animation-timing-function: ease-in-out;
  animation-duration: 5s;
  animation-delay: 250ms;
  animation-iteration-count: infinite;
}

.text-red-straboscope {
  color: #E34F5B;
}

.text-orange-straboscope {
  color: #EE8F2F;
}

.background-red-straboscope {
  background-color: #E34F5B;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  animation-name: background-red-straboscope;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 5s;
  animation-delay: 250ms;
  animation-iteration-count: infinite;
}

.background-orange-straboscope {
  background-color: #EE8F2F;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  animation-name: background-orange-straboscope;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 6s;
  animation-delay: 250ms;
  animation-iteration-count: infinite;
}

.text-red-straboscope .option__title,
.text-orange-straboscope .option__title,
.background-red-straboscope .option__title,
.background-orange-straboscope .option__title {
  padding-left: 15px;
}

@keyframes text-straboscope {
  50% {
    color: black;
  }
}

@keyframes background-red-straboscope {
  50% {
    background-color: white;
    color: #E34F5B;
  }
}

@keyframes background-orange-straboscope {
  50% {
    background-color: white;
    color: #EE8F2F;
  }
}

.set-all-stock-status-wrapper,
.stock-status-wrapper {
  background-color: #F2F2F2;
}

.set-all-status-text {
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  border: 1px solid rgba(27, 30, 56, 0.25);
  padding: 5px 10px;
  background-color: #fff;
  width: 140px;
}

.set-all-stock-status-wrapper .bx-plus,
.stock-status-wrapper .bx-plus {
  margin-left: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #29BBC1;
  border-radius: 3px;
  border: 1px solid rgba(27, 30, 56, 0.25);
  padding: 5px 10px;
  background-color: #fff;
  cursor: pointer;
}

.stock-status-line {
  width: 100%;
  height: 3px;
  margin-top: 10px;
  background-color: #b4c7e7;
}

.stock-status-line-mobile {
  display: none;
}

.set-all-stock-status-line {
  width: 3px;
  height: 40px;
  background-color: #b4c7e7;
}

.set-all-stock-status-input {
  width: 240px;
  padding-right: 30px;
}

.stock-status-input {
  width: 150px;
}

.bx-trash {
  position: absolute;
  right: 10px;
  top: 6px;
  font-size: 18px;
  color: #cc753b;
  cursor: pointer;
}

.set-all-stock-status-wrapper .bx-trash {
  position: absolute;
  left: 234px;
  top: 10px;
  font-size: 18px;
  color: #cc753b;
  cursor: pointer;
}

.stock-status-input > .bx-trash {
  position: absolute;
  font-size: 18px;
  color: #cc753b;
  cursor: pointer;
}

@media screen and  (max-width: 580px) {
  .list-group-2 {
    flex-direction: column !important;
  }

  .stock-status-line {
    display: none;
  }

  .stock-status-line-mobile {
    display: block;
    width: 3px;
    height: 40px;
    background-color: #b4c7e7;
  }

  .stock-status-input {
    margin-left: 13px !important;
    width: 240px !important;
  }

  .list-group-2 .bx-trash {
    top: 10px;
  }
}

.theme-supplier label.radio > input[type="radio"]:checked:disabled + span.icon i,
.theme-supplier label.radio > input[type="radio"]:checked:disabled + span.icon,
.theme-supplier label.radio > input[type="radio"]:disabled + span.icon i,
.theme-supplier label.radio > input[type="radio"]:disabled + span.icon {
  border-color: lightgray !important;
  color: lightgray;
  cursor: not-allowed;
}
</style>

<style>
.disabled-vue-editor-non-returnable .ql-toolbar {
  display: none !important;
}
.disabled-vue-editor-non-returnable,
.disabled-vue-editor-non-returnable .quillWrapper{
  width:100%;
}

.disabled-vue-editor-non-returnable .ql-container {
  //width: 650px !important;
  border-top: 1px solid #ccc !important;
}

.theme-supplier .settings-rfq-page .empty-editor .ql-editor {
  background-color: rgb(255,201,201) !important;
}

.theme-supplier .settings-rfq-page .ql-toolbar.ql-snow {
  border-radius: 3px 3px 0 0;
}

.theme-supplier .settings-rfq-page .ql-container.ql-snow {
  border-radius: 0 0 3px 3px;
}

.theme-supplier .settings-rfq-page .form-check-inline.radio {
  width: 80px;
}

.theme-supplier .settings-rfq-page .multiselect__tags {
  height: auto;
}

.modal-custom-non-returnable-modal .modal-title {
  color: #fff;
  margin-left: 15px;
}

.modal-custom-non-returnable-modal {
  max-width: 650px !important;
  margin: 15rem auto !important;
}

.non-returnable-text-message {
  height: 100px !important;
}

.modal-custom-non-returnable-modal .modal-body {
  padding-bottom: 0;
}

.modal-custom-non-returnable-modal .modal-footer {
  flex-wrap: nowrap !important;
  padding-top: 0;
}

.modal-custom-non-returnable-modal .modal-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-custom-non-returnable-modal .modal-header {
  background-color: #29BBC1;
}

.theme-repairer .modal-custom-non-returnable-modal .modal-header {
  background-color: #79B1BB;
}

.modal-custom-non-returnable-modal .modal-content {
  border-radius: 3px !important;
}

.modal-custom-non-returnable-modal .input-btn {
  font-size: 1rem !important;
  height: auto !important;
  padding: 0.75rem 1rem !important;
  width: auto !important;
}

.theme-supplier .modal-custom-non-returnable-modal .input-btn {
  background-color: #29BBC1 !important;
  border-color: #29BBC1 !important;
}

.theme-repairer .modal-custom-non-returnable-modal .input-btn {
  background-color: #79B1BB !important;
  border-color: #79B1BB !important;
}

.modal-custom-non-returnable-modal .cancel {
  background-color: #fff !important;
  border-color: rgba(28, 31, 57, .25) !important;
}

.modal-custom-non-returnable-modal .input-btn:focus {
  box-shadow: none !important;
}

.modal-custom-non-returnable-modal .dropzone-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .modal-custom-non-returnable-modal {
    margin: 8rem auto !important;
  }
}

.modal-custom-non-returnable-modal .control-elem-1__item {
  display: block;
  box-sizing: border-box;
  padding: 10px 10px;
  position: absolute;
  top: 47px;
  right: 20px;
  z-index: 9;
  height: 40px;
  border-radius: 3px;
  background-color: #FFFFFF;
  transition: opacity 150ms ease-in;
  border: 1px solid rgba(27, 30, 56, 0.25);
  cursor: pointer;
  opacity: 0;
}

.modal-custom-non-returnable-modal .control-elem-1__item:hover {
  opacity: 1;
}

.modal-custom-non-returnable-modal .control-elem-1__item i {
  font-size: 18px;
  color: #FF5E5E;
}

.mark-as-returned-signature {
  border: 1px solid rgba(28, 31, 57, 0.25) !important;
  border-radius: 3px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.mark-as-returned-signature:hover + .control-elem-1__item {
  opacity: 1;
}

.modal-custom-non-returnable-modal .non-returnable-signature-canvas {
  height: 100px !important;
  width: 100% !important;
  border: 1px solid rgba(28, 31, 57, 0.25) !important;
  border-radius: 3px;
}

.non-returnable-signature-canvas:hover + .control-elem-1__item,
.non-returnable-signature-block:hover + .control-elem-1__item {
  opacity: 1;
}
</style>

<style scoped>
.theme-supplier .settings-rfq-page .empty-field {
  background-color: rgb(255,201,201) !important;
}

.primary-show-example-btn {
  border: 2px solid #29BBC1;
}

.group-title {
  height: 48px;
  background-color: rgba(27, 30, 56, 0.12) !important;
  color: #1C1F39 !important;
  font-size: 13px !important;
  font-weight: bold;
  line-height: 18px;
  padding: 15px;
  margin-bottom: 1px;
  border-radius: 3px;
}

.input-btn.decline {
  background-color: white !important;
  color: #1C1F39;
}
</style>
