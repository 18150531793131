/* STYLE MIGRATION */
/* Component PartListView-ordered */
.part-list-item-alt-name {
    opacity: 0.5;
    color: #1C1F39;
    font-size: 12px;
    font-weight: bold;
}

.part-list-item-alt-name:first-child {
    padding-top: 5px;
}

.part-list-item-alt-name.linked {
    margin-left: 20px !important;
}

.part-list-part-name.linked .part-list-item-name {
    margin-left: 20px !important;
}

.gray-button{
    background-color: #ccc !important;
    border-color: #ccc !important;
    color:  #1C1F39 !important;

}

.part-list {
    font-family: "Inter",serif !important;
    font-weight: 600  !important;
}

.part-list .b-table-sticky-header .table.b-table > thead > tr > th {
    background-color: #efeff1 !important;
    &:hover {
        cursor: pointer;
    }
}
.part-list .b-table-sticky-header {
  overflow-x: scroll;
  max-height: 690px;
}

/* scroll style */
.part-list .b-table-sticky-header::-webkit-scrollbar {
    width: 7px;
}

.part-list .b-table-sticky-header--x::-webkit-scrollbar {
    width: unset;
    height: 7px;
}

.part-list .b-table-sticky-header::-webkit-scrollbar-thumb {
    background-color: #b7b8c0; /* #5e79ff; */
}

.part-list .b-table-sticky-header {
    scrollbar-width: thin;
    scrollbar-color: #b7b8c0; /* #5e79ff; */
}
/* end scroll style */

.color-grey{
    color: #ccc !important;
}

.b-table-highlight-all thead th.highlight-col:hover::after {
    background-color: #ccc;
    content:'\00a0';
    height: 721px;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 22;
    opacity: 0.2;
    cursor: pointer !important;
}

th.selected-col:after,
th.selected-col:before,
th.selected-col:hover:after,
th.selected-col:hover:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
}

th.selected-col:before{
    top: 0;
    border: 2px solid rgba(46, 169, 255, 1);
    /*height: 125px;*/
    height: 100%;
    z-index: 23;
}

th.selected-col{
    cursor: pointer;
}

button.order {
    height: 38px;
    width: 78px;
    border-radius: 3px;
}

.btn-link.order {
    color: var(--theme-color);
    padding: 0;

    &:hover {
        color: var(--theme-color);
        background-color: var(--gray-50);
    }
}

.part-list-item-priced{
    opacity: 1  !important;
    color: #1C1F39 !important;
}

.V3 .rfq-view-page .part-list-supplier-without-border:hover {
    // border: 1px solid #dee2e6 !important;
    // border-left-color: transparent !important;
    // border-right-color: transparent !important;
    // cursor: initial !important;
}

.ordered-button {
    opacity: 0.5 !important;
    background-color: #79B1BB !important;
    color: #1c1f39 !important;
}

.not-active-button {
    opacity: 1 !important;
    background-color: #fff !important;
    color: #79B1BB !important;
    border: solid 1px #79B1BB !important;
    font-weight: bold;
    border-radius: 3px;
}

.check-icon{
    color: #2DCA73;
}

.part-list-item-qty-ordered{
    display: inline-block;
    position: relative;
    width: 60px;
    top: -1px;
}

.part-list-item-qty-ordered span, .part-list-item-qty-ordered i {
    vertical-align: middle;
}



td.part-list-part-name {
    width: 100% !important;

    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;

    min-height: 47px !important;
    height: auto !important;

}

// .rfq-view-page .part-list .part-list-part-name {
.part-list-part-name {

    &__group {
        display: flex;
        flex-flow: column nowrap;
        align-items: top;
    }

    &.has-alt {
        .part-list-item-name {
            margin-bottom: 4px;
        }
    }

    &.linked {
        .part-list-part-name__name,
        .part-list-item-name {
            opacity: 0.5;
            color: #1C1F39;
        }
    }
    &-submitted {
        width: 40%;
        min-width: 200px;
    }

    &__icon-group {
        display: inline-block;
        margin: 0 0 0 auto;
        display: flex;
        align-items: center;
    }

    &__icon {
        font-size: 24px;
        color: grey;
        margin-right: 10px;
    }
}

.rfq-order-page .part-list-item-price {
    display: flex;
    align-items: center;
}

.part-list-item-price {
    position: relative;

    .bx:hover {
        cursor: pointer;
    }

    &.has-icon {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
    }

    &__value {
        flex: 1 1 100%;
        .has-icon & {
            padding-left: 20px;
        }
    }

    &__icon-group {
        width: 20px;
        min-width: 20px;
        flex: 0 1 0px;
        position: relative;
        .has-icon & {
            display: inline-block;
        }
    }

}

/* Component PartListView */
.qty-tooltip {
  top: 10px !important;
  left: 8px !important;
}

.qty-icon-arrow {
  font-size: 15px;
  transform: translateY(2.45px);
  font-weight: bolder;
}

.qty-icon-arrow-up {
  color: #17ae58
}

.qty-icon-arrow-down {
  color: red
}

.part-list-item-alt-name {
    opacity: 0.5;
    color: #1C1F39;
    font-size: 12px;
    font-weight: bold;
}

.part-list-item-name-original-original-linked {
  opacity: 1 !important;
  color: #1C1F39 !important;
}

.part-list .b-table-sticky-header .table.b-table > thead > tr > th.part-list-number {
    min-width: 130px;
}

  .part-list-item-alt-name:first-child {
    padding-top: 5px;
  }

  .gray-button {
    background-color: #ccc !important;
    border-color: #ccc !important;
    color: #1C1F39 !important;

  }

  .green-order {
    background-color: #17ae58;
  }

  .part-list {
    font-family: "Inter", serif !important;
    font-weight: 600 !important;
  }

  .part-list .b-table-sticky-header .table.b-table > thead > tr > th {
    background-color: #efeff1 !important;

    &:hover {
      cursor: pointer;
    }
  }

  /* scroll style */
  .part-list .b-table-sticky-header::-webkit-scrollbar {
    width: 7px;
  }

  .part-list .b-table-sticky-header--x::-webkit-scrollbar {
    width: unset;
    height: 7px;
  }

  .part-list .b-table-sticky-header::-webkit-scrollbar-thumb {
    background-color: #b7b8c0; /* #5e79ff; */
  }

  .part-list .b-table-sticky-header {
    scrollbar-width: thin;
    scrollbar-color: #b7b8c0; /* #5e79ff; */
  }

  /* end scroll style */

  .color-grey {
    color: #ccc !important;
  }

  .b-table-highlight-all thead th.highlight-col:hover::after {
    background-color: #ccc;
    content: '\00a0';
    height: 721px;
    top: 0px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 22;
    opacity: 0.2;
    cursor: pointer !important;
  }

  th.selected-col:after,
  th.selected-col:before,
  th.selected-col:hover:after,
  th.selected-col:hover:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
  }

  th.selected-col:before {
    top: 0;
    border: 2px solid rgba(46, 169, 255, 1);
    /*height: 125px;*/
    height: 100%;
    z-index: 23;
  }

  th.selected-col {
    cursor: pointer;
  }

  button.order {
    height: 38px;
    width: 78px;
    border-radius: 3px;
  }

  .btn-link.order {
    color: var(--theme-color);
    padding: 0;

    &:hover {
      color: var(--theme-color);
      background-color: var(--gray-50);
    }
  }

  .part-list-item-priced {
    opacity: 1 !important;
    color: #1C1F39 !important;
  }

  .V3 .rfq-view-page .part-list-supplier-without-border:hover {
    // border: 1px solid #dee2e6 !important;
    // border-left-color: transparent !important;
    // border-right-color: transparent !important;
    cursor: initial !important;

  }

  .ordered-button {
    opacity: 0.5 !important;
    background-color: #79B1BB !important;
    color: #1c1f39 !important;
  }

  .not-active-button {
    opacity: 1 !important;
    background-color: #fff !important;
    color: #79B1BB !important;
    border: solid 1px #79B1BB !important;
    font-weight: bold;
    border-radius: 3px;
  }

  .check-icon {
    color: #2DCA73;
  }

  .part-list-item-qty-ordered {
    display: inline-block;
    position: relative;
    width: 60px;
    top: -1px;
  }

  .part-list-item-qty-ordered span, .part-list-item-qty-ordered i {
    vertical-align: middle;
  }


  td.part-list-part-name {
    width: 100% !important;

    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;

    min-height: 47px !important;
    height: auto !important;

  }

  // .rfq-view-page .part-list .part-list-part-name {
  .part-list-part-name {

    &__group {
      display: flex;
      flex-flow: column nowrap;
      align-items: top;
    }

    &.has-alt {
      .part-list-item-name {
        margin-bottom: 4px;
      }
    }

    &.linked {

      .part-list-part-name__name,
      .part-list-item-name {
        opacity: 0.5;
        color: #1C1F39;
      }
    }

    &-submitted {
      width: 40%;
      min-width: 200px;
    }

    &__icon-group {
      display: inline-block;
      margin: 0 0 0 auto;
      display: flex;
      align-items: center;
    }

    &__icon {
      font-size: 24px;
      color: grey;
      margin-right: 10px;
    }
  }

  .part-list-item-price {
    position: relative;

    .bx:hover {
      cursor: pointer;
    }

    &.has-icon {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }

    &__value {
      flex: 1 1 100%;

      .has-icon & {
        padding-left: 20px;
      }
    }

    &__icon-group {
      flex: 0 1 0px;
      position: relative;

      .has-icon & {
        display: inline-block;
      }
    }

  }

/* Component RfqTimer */
.theme-supplier,
.theme-repairer
 {
    .timer {
        color: #1C1F39;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
        margin-bottom: 15px;

        @media screen and (min-width: 1200px) {
            margin-top: 45px;
        }

        .like span {
            vertical-align: middle;
            font-size: 13px;
        }

        .like i.theme-color{
            cursor: default !important;
        }

        .like i{
            cursor: pointer !important;
        }

        .like {
            font-size: 24px;
            margin: 0 0 0 0;
            min-width: 200px;
            z-index: 99999;
        }

        .bx-like-default-color{
            color: #8d8fa3;
        }
    }

    .timer__row {
        span {
            display: inline-block;
            &:first-child {
                width: 140px;

                font-weight: 700;
            }
            &:nth-child(2) {
                width: auto;
                margin-left: 5px;
                margin-right: 20px;
            }
            &:nth-child(3) {
                font-weight: 700;
            }
        }
    }
}

/* Component PartNameMenu */
.partname-menu {
    // reset
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    padding: 0 !important;
    margin: 0;
    box-sizing: border-box;

    &__item {
        position: relative;
        border: 0;
        background-color: white;

        &.has-sub:active,
        &.has-sub:hover,
        &.has-sub:focus {
            .partname-submenu {
                display: inline-block;
            }
        }

        &:first-child {
            border-radius: 5px 5px 0px 0px;

            a {
                border-radius: inherit
            }
        }

        &:last-child {
            border-radius: 0px 0px 5px 5px;

            a {
                border-radius: inherit
            }
        }

        &:focus,
        &:hover {
            background-color: whitesmoke;
            a {
                color: #000000;
            }

        }
    }

    &__link {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        text-align: left;

        padding: 10px 15px;
        min-width: 100px;
        color: inherit;
    }

    i {
        font-size: 18px;
        margin-bottom: 2px;
    }
}

.partname-submenu {
    list-style: none;
    display: none;
    position: absolute;
    top: 0px;
    left: 100%;
    background: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    border: 1px solid #bdbdbd;
    border-radius: 5px;
    z-index: 1002;

    &__input {
        padding: 5px;
        border: 1px solid #bdbdbd;
        border-radius: 5px;
        margin: 10px 10px;
    }

    &__list {
        display: block;
        padding: 0px;
        margin: 0px;

        max-height: 200px;
        overflow-y: scroll;

        &:last-child {
            border-radius: 0px 0px 5px 5px;

            a {
                border-radius: inherit
            }
        }
    }

    &__item {
        display: block;
        list-style: none;
        &:focus,
        &:hover {
            > * {
                cursor: pointer;
                background-color: #F3F4F5;
                color: inherit;
            }
        }
    }
}

/* Component PartsOrderDetails */
.parts-order-details {
  padding-left: 10px;
  color: #1C1F39;

  &__label {
      white-space: nowrap;
  }
}

/* Component purchase-detail */
.color-green {
    color: #17ae58;
}
.color-red {
  color: red !important
}

.oem-color {
    color: #17a2ac;
}

.oem-purchase-detail__label {
    opacity: 1 !important;
}

.purchase-detail {
  position: relative;
  border: 1px solid rgba(27,30,56,0.25);
  border-radius: 3px 3px 0 0;
  &:not(:first-child) {
      top: -1px;
      border-top: 0px;
      border-radius: 0 0 3px 3px;
  }

  &__body {
      padding: 1rem;
  }
  &__title {
      margin-bottom: 15px;
  }
  &__item {
      margin-bottom: 15px;
  }
  &__label {
      opacity: 0.5;
  }
}

/* Component StockComment */
.stock-comment {
  display: inline-block;
  position: absolute;
  opacity: 0;
  right: -110%;
  z-index: 10001;
  left: 0px !important;
  top: 72px !important;

  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 10px;
  width: 210px;
  height: 180px;
  background: #FFFFFF;
  text-align: left;

  &:hover {
      cursor: text;
  }

  .active & {
      opacity: 1;
  }

  .stock-text {

      // border: 1px solid rgba(0, 0, 0, .15);
      // border-radius: 5px;
      // padding-top: 10px;
      // padding-bottom: 10px;
      resize: none;
      width: 190px;
      height: 160px;
      // background: #FFFFFF;
      // text-align: left;
  }

  &__info {
      opacity: 0.5;
      position: absolute;
      bottom: 10px;
      left: 10px;
  }

  &__preset-group {
      margin-bottom: 10px;
  }

  &__preset {
      padding: 2.5px 5px !important;
  }

}

/* Component StockSelect */
.stock-select {
  display: flex;
  position: relative;
  flex-flow: column wrap;
  color: #35495E;

  &__selected {
      display: block;
      position: relative;
      white-space: nowrap;
      min-height: 17px;
      line-height: 16px;
      font-size: 12px;
      line-height: 1.2;
      font-weight: 600;
      height: 32px;

      padding: .5rem 40px .5rem .75rem;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, .15);
      background: #FFFFFF;
      width: 100%; // 160px;

      text-align: left;
      &:hover {
          cursor: pointer;
      }
  }

  &__dropdown-icon {
      font-size: 23px;
      line-height: 1;
      top: 3px;
      right: 7px;
      position: absolute;
      cursor: pointer;
      font-weight: 600;
      transition: transform 200ms ease-out;
      &.active {
          transform: rotate(180deg);
      }
  }

  &__select {
      display: flex;
      opacity: 0;
      visibility: hidden;
      flex-flow: column nowrap;
      position: absolute;
      align-items: flex-start;
      top: 33px;
      width: 100%;
      border-radius: 5px;
      background: white;
      transition: opacity 200ms ease-out;

      max-height: 270px;
      overflow-y: auto;
      overflow-x: inherit;

      border: 1px solid rgba(0, 0, 0, 0.15);
      &.active {
          opacity: 1;
          visibility: visible;
      }
      z-index: 1000;
  }

  &__option {
      // button override
      border: 0px;
      background-color: white;

      display: inline-block;
      position: relative;
      padding: 10px;
      width: 100%;
      text-align: left;

      &.active {
          background-color: rgba(41, 187, 193, 0.1);
          color: #192627;
          font-weight: bold;
          // .stock-select__comment {
          //     display: inline-block;
          // }
      }

      &:hover,
      &:focus {
          cursor: pointer;
          background-color: rgba(41, 187, 193, 0.1);
          color: #192627;
          font-weight: bold;
      }

      i {
          font-size: 20px;
          position: absolute;
          top: 7px;
          right: 2px;
      }
  }
}

/* Component tabTable */
// REFACTOR:  Move to utility file
    // spec: - should available global

    .font-inter {
      font-family: "Inter", sans-serif;
      &--bold {
          font-weight: 700;
      }
      &--semibold {
          font-weight: 600;
      }
  }

  .font-11 {
      font-size: 11px !important;
      line-height: 15px !important;
      letter-spacing: normal;
  }

  .font-12 {
      font-size: 12px !important;
      line-height: 16px !important;
      letter-spacing: normal;
  }

  .font-13 {
      font-size: 13px !important;
      line-height: 18px !important;
      letter-spacing: normal;
  }

  .font-16 {
      font-size: 16px !important;
      line-height: 22px !important;
      letter-spacing: normal;
  }

  // -- end REFACTOR
  .table-header {
      @extend .font-inter;
      @extend .font-inter--bold;
      @extend .font-13;
  }

  .table-col {
      @extend .font-inter;
      @extend .font-12;
      &--bold {
          @extend .font-inter--bold;
      }
      &--semibold {
          @extend .font-inter--semibold;
      }
  }

  .tab-table {
      --color-th: rgba(27,30,56,0.1);

      .theme-supplier & {
          --color-theme: #29BBC1;
      }
      .theme-repairer & {
          --color-theme: #79B1BB;
      }

      padding: 15px 10px 18px 10px;
      background-color: white;
      border-radius: 3px;
      margin-bottom: 15px;
      &__link {
          line-height: 18px;
          color: var(--color-theme) !important;
          &:focus {
              box-shadow: none !important;
          }
      }
      &__link--more.btn-link {
          @extend .font-13;
          @extend .font-inter;
          @extend .font-inter--bold;
          padding: 0;
      }
      .table-responsive {
          margin-bottom: 0;
      }
      table.table.b-table > thead > tr > th {
          background-color: var(--color-th) !important;
      }
      .table.b-table > caption {
          background-color: white;
          padding: 0;
          padding-top: 20px;
      }
  }

/* Component */


/* Component */


/* Component */
