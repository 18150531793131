<template>
    <div class="page invite-repairer">
        <div class="container">
            <div class="auth-header">
                <div class="logo">
                  <router-link to="/login" class="logo-text"><span style="color: #14b4bb">Parts</span><span style="color:#c1c0c0;">Search</span></router-link>
                </div>
            </div>
            <div class="invite-repairer-form text-center">
                <div class="intro-text">
                    <div class="form">
                        <div class="steps-line">
                            <ul>
                                <li class="active">
                                    <span class="numb">1</span>
                                    <span class="step-title">Your Business</span>
                                </li>
                                <li class="line"></li>
                                <li>
                                    <span class="numb">2</span>
                                    <span class="step-title">Business Profile</span>
                                </li>
                                <li class="line"></li>
                                <li>
                                    <span class="numb">3</span>
                                    <span class="step-title">Your Details</span>
                                </li>
                            </ul>
                        </div>
                        <br />
                        <br />
                        <h1>Find your Business</h1>
                        <br />
                        <form method="POST" @submit.prevent="login">
                            <div class="form-group custom-multiselect">
                                <multiselect
                                    :loading="isLoading"
                                    :custom-label="nameWithAbn"
                                    :options="options"
                                    :showLabels="false"
                                    :max-height="203"
                                    :close-on-select="true"
                                    :internal-search="false"
                                    :searchable="true"
                                    :clear-on-select="false"
                                    :show-no-results="true"
                                    open-direction="bottom"
                                    v-model="selectedAbn"
                                    placeholder="Search for your Business by Name or ABN"
                                    @search-change="companySearchChanges"
                                >
                                  <template slot="noResult">
                                    We could not find and match a company based on that information. Please try again.
                                  </template>
                                </multiselect>
                            </div>
                            <p class="text-center">
                                Can’t find your business?
                                <router-link
                                    :to="'/register/supplier/fill-business-details'"
                                >Continue to create your business here</router-link>
                            </p>
                            <div class="form-group">
                                <button
                                    ref="nextBtn"
                                    v-if="selectedAbn"
                                    type="button"
                                    @click="nextStep"
                                    class="btn input-btn"
                                >Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
@import "../../../../styles/repairer/invite/style.css";
</style>
<style lang="css" scoped>
.form {
    max-width: 771px;
    width: 100%;
    margin: 0 auto;
}
.steps-line {
    max-width: 652px;
    width: 100%;
    margin: 0 auto;
}
.form h1 {
    text-align: center;
    margin-bottom: 0px;
}
.invite-repairer-form {
    justify-content: flex-start;
    margin-top: 15px;
}
.form .input-btn {
    max-width: 432px;
    display: table;
    margin: 0 auto;
    margin-top: 22px;
}
</style>
<script>
import Multiselect from "vue-multiselect";
import Axios from "axios";
export default {
    name: "SupplierFindBusiness",
    components: {
        multiselect: Multiselect
    },
    data() {
        return {
            selectedAbn: null,
            lastTimeout: null,
            lastRequest: null,
            isLoading: false,
            options: [],
            registrationData: {},
        };
    },
    mounted() {
      if(localStorage.getItem('registrationData')){
        try {
          this.registrationData = JSON.parse(localStorage.getItem('registrationData'));
        } catch (e) {
          localStorage.removeItem('registrationData');
        }
      }
    },
    methods: {
      nextStep(){
        this.$refs.nextBtn.innerText = "Loading your company data...";

        Axios.post(
            '/ir/auth/get-company-data',
            {
              abn: this.selectedAbn.abn,
            },
            {
              cancelToken: this.lastRequest.token,
            }
        ).then(res => {
          this.$refs.nextBtn.innerText = "Next";
          if(res.data.status){

            this.registrationData.companyDataFromAbn = res.data.data;

            localStorage.registrationData = JSON.stringify(this.registrationData);

            this.$router.push("/register/supplier/fill-business-details");

          }
        }).catch(() => {
          this.$refs.nextBtn.innerText = "Next";
        });

      },
      nameWithAbn({name}){
        return name;
      },
      companySearchChanges(query){
        if (query == '') {
          return
        }
        // console.log('KEYUP')
        this.options = []
        if(this.lastTimeout){
          clearTimeout(this.lastTimeout);
          this.isLoading = false;
        }
        if(this.lastRequest){
          this.lastRequest.cancel();
          this.lastRequest = null;
          this.isLoading = false;
        }

        this.lastTimeout = setTimeout(() => {

          this.lastRequest = Axios.CancelToken.source();
          this.isLoading = true;

          Axios.post(
            '/ir/auth/find-company',
            {
              companyName: query
            },
            {
              cancelToken: this.lastRequest.token
            }
          ).then(res => {
            console.log(res.data);
            this.options = res.data;
            this.isLoading = false;
          }).catch(() => {
            //console.warn(err);
            this.isLoading = false;
          });

        }, 300);

      }
    },
    computed: {

    }
};
</script>
<style>

  .multiselect__spinner {
    z-index: 10;
  }

</style>
